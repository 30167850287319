import {
  configureStore, ThunkAction, Action, createAction, combineReducers
} from '@reduxjs/toolkit';

import addGift from './addGift';
import auth from './auth';
import cart from './cart';
import deliver from './deliver';
import favoriteProduct from './favoriteProduct';
import news from './news';
import notification from './notification';
import orders from './orders';
import player from './player';
import product from './product';
import { apiBanner } from './rtk/banner';
import { apiBlock } from './rtk/block';
import system from './system';

const reducer = combineReducers({
  auth,
  cart,
  product,
  news,
  notification,
  deliver,
  player,
  addGift,
  system,
  favoriteProduct,
  orders,
  [apiBlock.reducerPath]: apiBlock.reducer,
  [apiBanner.reducerPath]: apiBanner.reducer
});

export const resetAction = createAction('reset');

const resettableReducer = (state: any, action: any) => {
  if (resetAction.match(action)) {
    return reducer(undefined, action);
  }
  return reducer(state, action);
};

export const store = configureStore({
  devTools: process.env.NODE_ENV === 'development',
  reducer: resettableReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  }).concat([apiBanner.middleware, apiBlock.middleware]),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

/* eslint-disable max-len */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  builderProductGroups,
  builderProductTypes,
  builderProductColors,
  builderProductFavorite,
  builderProductViewed,
  builderProductPopular,
  builderProductTopSale,
  builderProductBought,
  builderProductInterested,
} from './action';
import State from './types';

const initialState: State = {
  groups: [],
  groupsLoading: false,
  colors: [],
  colorsLoading: false,
  types: [],
  typesLoading: false,
  topSales: [],
  favorite: [],
  viewed: [],
  popular: [],
  bought: [],
  interested: [],
};

const productSlice = createSlice({
  name: 'productReducer',
  initialState,
  reducers: {
    updateFavoriteAction: (
      $state,
      action: PayloadAction<{ product: IProductV2, isFavorite: boolean }>
    ) => {
      if (!action.payload.isFavorite) {
        $state.favorite = $state.favorite.filter((x) => x.id !== action.payload.product.id);
      }
      if (action.payload.isFavorite) {
        $state.favorite = $state.favorite
          .filter((x) => x.id !== action.payload.product.id)
          .concat(action.payload.product);
      }
    },
    updateGroupAction: ($state, { payload }: PayloadAction<{ groups: State['groups']; colors: State['colors'] }>) => {
      $state.groups = payload.groups;
      $state.colors = payload.colors;
    },
  },
  extraReducers(builder) {
    builderProductGroups(builder);
    builderProductColors(builder);
    builderProductTypes(builder);
    builderProductFavorite(builder);
    builderProductViewed(builder);
    builderProductPopular(builder);
    builderProductTopSale(builder);
    builderProductBought(builder);
    builderProductInterested(builder);
  }
});

export const {
  updateGroupAction,
  updateFavoriteAction
} = productSlice.actions;

export default productSlice.reducer;

import React from 'react';

import Icon, { IconName, IconSize } from 'components/atoms/Icon';
import Link from 'components/atoms/Link';
import mapModifiers from 'utils/functions';

type Sizes = 'xs' | 'xs-large' | 'sm-small' | 'sm' | 'md' | 'lg';
type BgColor = 'bg-white' | 'bg-redPelati' | 'bg-northTexasGreen' | 'bg-orangeBronze' | 'bg-northTexasGreen02' | 'bg-whiteSmoke1' | 'bg-rossoCorsa' | 'bg-isabelline2';
type TextColor = 'text-white' | 'text-jet' | 'text-northTexasGreen' | 'text-eerieBlack' | 'text-dodgerBlue' | 'text-raisinBlack' | 'text-dimGray' | 'text-rossoCorsa';
type BorderColor = 'border-pastelGray' | 'border-eerieBlack' | 'border-northTexasGreen' | 'border-dodgerBlue' | 'border-orangeBronze' | 'border-isabelline2' | 'border-rossoCorsa' | 'border-spanishGray';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  modifiers?: (BgColor | TextColor | BorderColor | Sizes)[];
  loading?: boolean;
  href?: string;
  target?: string;
  iconLoading?: IconName;
  isBorderRadius?: boolean;
  icon?: {
    iconName: IconName;
    size?: IconSize;
  }
}

const Button: React.FC<ButtonProps> = ({
  modifiers = ['bg-northTexasGreen', 'text-white', 'md'],
  isBorderRadius,
  type,
  loading,
  iconLoading,
  href,
  target,
  children,
  icon,
  ...props
}) => {
  if (href) {
    return (
      <Link href={href} target={target} className={mapModifiers('a-button', modifiers, loading && 'loading', isBorderRadius && 'borderRadius')}>
        {icon && <span className="u-mr-8"><Icon {...icon} /></span>}
        <span className="a-button_text">
          {children}
        </span>
      </Link>
    );
  }

  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type || 'button'}
      className={mapModifiers('a-button', modifiers, loading && 'loading', isBorderRadius && 'borderRadius')}
      {...props}
    >
      {icon && <span className="u-mr-8"><Icon {...icon} /></span>}
      <span className="a-button_text">
        {children}
      </span>
      {loading && (
        <span className="a-button_loading">
          <Icon
            iconName={iconLoading || 'loadingWhite'}
            size="24"
          />
        </span>
      )}
    </button>
  );
};

export default Button;

import { DeliveryDateData, DeliveryDateParams, IDeliveryDate } from './types';

import axiosInstance from 'services/common/instance';

export const apiDeliveryDate = async (): Promise<IDeliveryDate> => {
  const res = await axiosInstance.get('/api/v1/delivery-date');
  return res.data.data;
};

export const apiV2GetDeliveryDate = async (params: DeliveryDateParams):
  Promise<DeliveryDateData> => {
  const res = await axiosInstance.get('/api/v2/orders/delivery-dates', { params });
  return res.data;
};

export const remove = '';

import { createAsyncThunk } from '@reduxjs/toolkit';

import { apiGetNews } from 'services/news';
import { NewsPayload, INews } from 'services/news/types';
import { apiGetWholesaleCustomer } from 'services/wholesale';
import { IWholesaleCustomer } from 'services/wholesale/type';

export const getNewsAsync = createAsyncThunk<APIPaginationResponse<INews[]>, NewsPayload, {}>('news/list', async (payload, { rejectWithValue }) => {
  try {
    const res = await apiGetNews(payload);
    return res;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getListWholesaleAsync = createAsyncThunk<APIPaginationResponse<IWholesaleCustomer[]>, NewsPayload, {}>('news/listWholeSale', async (payload, { rejectWithValue }) => {
  try {
    const res = await apiGetWholesaleCustomer(payload);
    return res;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const newsThunk = {
  getNewsAsync,
  getListWholesaleAsync
};

export default newsThunk;

import { unwrapResult } from '@reduxjs/toolkit';
import React, { Fragment, useEffect, useMemo } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import Layout from 'components/templates/Layout';
import { getAccessToken } from 'services/common/storage';
import { getProfileAsync } from 'store/auth/thunk';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import PATHS from 'utils/paths';

interface PrivateRouterRootProps {
  element: any;
  isPrivate?: boolean;
  layout?: any;
  pathRedirect?: string;
}

const PrivateRouterRoot: React.FC<PrivateRouterRootProps> = ({
  element,
  isPrivate,
  layout,
  pathRedirect
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const token = getAccessToken();
  const profile = useAppSelector((state) => state.auth.profile);
  const location = useLocation();

  useEffect(() => {
    if (!profile && token) {
      dispatch(getProfileAsync()).then(unwrapResult).catch(() => {
        navigate(PATHS.LOGIN);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Wrapper = useMemo(() => {
    if (layout === null) {
      return Fragment;
    }
    return layout || Layout;
  }, [layout]);

  const Page = useMemo(() => element || null, [element]);

  if (!token && !profile && isPrivate) {
    return (
      <Navigate
        to={{
          pathname: pathRedirect || PATHS.LOGIN,
          search: location.pathname !== PATHS.HOME ? `?redirect=${location.pathname}${location.search}` : undefined
        }}
        replace
      />
    );
  }
  return (
    <Wrapper>
      <Page />
    </Wrapper>
  );
};

export default PrivateRouterRoot;

import React, { useMemo, useState } from 'react';

import Dropdown from '../Dropdown';

import Icon from 'components/atoms/Icon';
import Link from 'components/atoms/Link';
import Typography from 'components/atoms/Typography';
import useLogout from 'hooks/useLogout';
import { useAppSelector } from 'store/hooks';
import PATHS from 'utils/paths';

const Account: React.FC = () => {
  const userInfo = useAppSelector((state) => state.auth.profile);
  const logout = useLogout();
  const [open, setOpen] = useState(false);

  const dropdownInfoUser = useMemo(() => [
    {
      label: 'Thông tin tài khoản',
      href: PATHS.ACCOUNT_INFORMATION,
    },
    {
      label: 'Lịch đặt hàng',
      href: PATHS.ORDER_SCHEDULE,
    },
    {
      label: 'Đơn hàng',
      href: PATHS.ACCOUNT_ORDER_MANAGEMENT,
    },
    {
      label: 'Đăng xuất',
      onClick: logout
    },
  ], [logout]);

  return (
    <>
      {userInfo && (
        <>
          <Dropdown.Wrap
            content={<Dropdown.User list={dropdownInfoUser} />}
            handleClose={() => setOpen(false)}
            isOpen={open}
            handleListItem={() => setOpen(false)}
          >
            {userInfo && (
              <div onClick={() => setOpen(!open)} className="o-header_topActions_actionItem">
                <Icon iconName="userGray" size="16" />
                <Typography.Paragraph type="div" content={`Chào ${userInfo?.full_name}`} modifiers={['14x20', '500', 'eerieBlack']} />
              </div>
            )}
          </Dropdown.Wrap>
          {/* <RelationshipTag label="Thân thiết" modifier="closest" /> */}
        </>
      )}
      {!userInfo && (
        <Link href={PATHS.LOGIN} className="o-header_topActions_actionItem anonymous">
          <Icon iconName="userGray" size="16" />
          <Typography.Paragraph type="div" content="Đăng nhập" modifiers={['14x20', '500', 'eerieBlack']} />
        </Link>
      )}
    </>
  );
};

export default Account;

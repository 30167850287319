/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import { LIST_STATUS_ORDER } from './constants';

function mapModifiers(
  baseClassName: string,
  ...modifiers: (string | string[] | false | undefined)[]
): string {
  return modifiers
    .reduce<string[]>(
      (acc, m) => (!m ? acc : [...acc, ...(typeof m === 'string' ? [m] : m)]),
      [],
    )
    .map((m) => `-${m}`)
    .reduce<string>(
      (classNames, suffix) => `${classNames} ${baseClassName}${suffix}`,
      baseClassName,
    );
}

export default mapModifiers;

/*!
 * Scroll down to next block element
 */
export function scrollDownNextSection(ref: React.RefObject<HTMLDivElement>) {
  if (ref && ref.current) {
    window.scrollTo({ behavior: 'smooth', top: ref.current.offsetTop - 68 }); // Minus header height
  }
}

/*!
 * getMousePosition(event) - cross browser normalizing of:
 * clientX, clientY, screenX, screenY, offsetX, offsetY, pageX, pageY
 * HTMLElement
 */
export function getMousePosition(
  evt:
    | React.MouseEvent<SVGPathElement, MouseEvent>
    | React.MouseEvent<SVGRectElement, MouseEvent>,
  item: HTMLDivElement,
) {
  let { pageX } = evt;
  let { pageY } = evt;
  if (pageX === undefined) {
    pageX = evt.clientX
      + document.body.scrollLeft
      + document.documentElement.scrollLeft;
    pageY = evt.clientY
      + document.body.scrollTop
      + document.documentElement.scrollTop;
  }

  const rect = item.getBoundingClientRect();
  const offsetX = evt.clientX - rect.left;
  const offsetY = evt.clientY - rect.top;

  return {
    client: { x: evt.clientX, y: evt.clientY }, // relative to the viewport
    screen: { x: evt.screenX, y: evt.screenY }, // relative to the physical screen
    offset: { x: offsetX, y: offsetY }, // relative to the event target
    page: { x: pageX, y: pageY }, // relative to the html document
  };
}

export function getDimensions(ele: HTMLDivElement) {
  const { height } = ele.getBoundingClientRect();
  const { offsetTop } = ele;
  const offsetBottom = offsetTop + height;

  return {
    height,
    offsetTop,
    offsetBottom,
  };
}

export function scrollStop(callback: (value: any) => void, time = 2000) {
  // Make sure a valid callback was provided
  if (!callback || typeof callback !== 'function') return;

  // Setup scrolling variable
  let isScrolling: any;

  // Listen for scroll events
  window.addEventListener(
    'scroll',
    () => {
      // Clear our timeout throughout the scroll
      window.clearTimeout(isScrolling);

      // Set a timeout to run after scrolling ends
      isScrolling = setTimeout(callback, time);
    },
    false,
  );
}
export function convertLocalToUTCDate(date: Date) {
  if (!date) {
    return date;
  }
  return new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()),
  );
}

export function range(start: number, end: number) {
  return new Array(end - start + 1).fill(undefined).map((_, i) => ({
    label: String(end - i),
    value: String(end - i),
  }));
}

export const years = range(1990, new Date().getFullYear() + 1);

export function getMonth(date: Date) {
  return new Date(date).getMonth();
}

export const months = [
  { label: 'January', value: 'January' },
  { label: 'February', value: 'February' },
  { label: 'March', value: 'March' },
  { label: 'April', value: 'April' },
  { label: 'May', value: 'May' },
  { label: 'June', value: 'June' },
  { label: 'July', value: 'July' },
  { label: 'August', value: 'August' },
  { label: 'September', value: 'September' },
  { label: 'October', value: 'October' },
  { label: 'November', value: 'November' },
  { label: 'December', value: 'December' },
];

export const handleScrollCenter = (
  ref: React.RefObject<HTMLDivElement | HTMLUListElement | null>,
  classNameEleActive: string,
) => {
  const eleScroll = ref.current;
  const eleActive = eleScroll && eleScroll.querySelector(classNameEleActive);
  if (!eleActive || !eleScroll) return;
  // get width element scroll
  const widthEleScroll = eleScroll.getBoundingClientRect().width;
  // get distance element scroll compared to y window
  const xEleScroll = eleScroll.getBoundingClientRect().x;
  // get width element active
  const widthEleActive = eleActive.getBoundingClientRect().width;
  // get distance element active compared to y window
  const xEleActive = eleActive.getBoundingClientRect().x;
  // get position sroll bar
  const positionScroll = eleScroll.scrollLeft;
  const scrollX = xEleActive - xEleScroll
    + widthEleActive / 2 + positionScroll - widthEleScroll / 2;
  eleScroll.scroll({
    left: scrollX,
    behavior: 'smooth',
  });
};

export const renderValue = (val?: number, prefix?: string) => {
  if (val === 0 && prefix) return `${val}${prefix}`;
  if (!val || !Number(val)) return '';
  const converted = Number(val).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  if (prefix) return `${converted}${prefix}`;
  return converted;
};

export const totalValueFormArray = (
  { array, prefix, isValue }
    : { array?: number[], prefix?: string, isValue?: boolean }
) => {
  const totalValue = (!array || array?.length <= 0)
    ? 0 : array?.reduce((item, total) => total + item);
  const converted = totalValue?.toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  if (prefix) return `${converted} ${prefix}`;
  if (isValue) return totalValue;
  return converted;
};

export const baseURL = (src?: string): string => src || '';

export const linkURL = (src?: string): string => src || '';

export const renderStatus = (status?: number) => {
  if (!status) return undefined;
  const find = LIST_STATUS_ORDER.find((x) => x.value === status);
  return find;
};

export const kFormatter = (num: number): string => (Math.abs(num) > 999 ? `${Math.sign(num) * Math.abs(num) / 1000}K` : `${Math.sign(num) * Math.abs(num)}đ`);

type ParaGetQuantityByRule = {
  quantity?:number,
  unit?:number,
  attr?:string,
  measure?:string
};

export const getQuantityByRule = (item:ParaGetQuantityByRule) => {
  const _quantity = item.quantity;
  const _unit = item.unit || 1;
  const _attr = item.attr || '';
  const _measure = item.measure || '';

  if (!_quantity) return '';
  if (_quantity % _unit === 0) {
    return `${_quantity / _unit} ${_attr}`;
  }

  if (((_quantity - (_quantity % _unit)) / _unit) <= 0) {
    return `${_quantity % _unit} ${_measure}`;
  }

  return `${(_quantity - (_quantity % _unit)) / _unit} ${_attr}, ${_quantity % _unit} ${_measure}`;
};

export const formatContentItemDiscount = (item:Discounts, detailProduct?: Omit<ParaGetQuantityByRule, 'quantity'>) => {
  const attr = detailProduct?.attr;

  const content = item.type === 0
    ? `Mua ${getQuantityByRule({ ...detailProduct || {}, quantity: item.min_quantity })} - giảm ${item?.discount_percent}%${attr ? `/${attr}` : ''}`
    : `Mua ${getQuantityByRule({ ...detailProduct || {}, quantity: item.min_quantity })} - giảm ${kFormatter((item?.promotional_price || 0) * (Number(detailProduct?.unit) || 1))}${attr ? `/${attr}` : ''}`;
  return content;
};

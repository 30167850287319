import { createAsyncThunk } from '@reduxjs/toolkit';

import { apiGetNotifications, apiSeenNotification } from 'services/notification';
import { INotification, NotificationPayload } from 'services/notification/types';

export const getNotificationsAsync = createAsyncThunk<
  APIPaginationResponse<INotification[]>, NotificationPayload, {}>(
    'notification/getList',
    async (params, { rejectWithValue }) => {
      try {
        return await apiGetNotifications(params);
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );

export const seenNotificationsAsync = createAsyncThunk<
  INotification, string, {}>(
    'notification/seen',
    async (id, { rejectWithValue }) => {
      try {
        return await apiSeenNotification(id);
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );

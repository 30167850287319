import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import State from './types';

const initialState: State = {
  srcBg: undefined,
  srcVideo: undefined,
  isOpen: false,
};

const playerSlice = createSlice({
  name: 'playerReducer',
  initialState,
  reducers: {
    openPlayerAction: ($state, action: PayloadAction<{ srcBg?: string; srcVideo?: string }>) => {
      $state.srcBg = action.payload.srcBg;
      $state.srcVideo = action.payload.srcVideo;
      $state.isOpen = true;
    },
    closePlayerAction: ($state) => {
      $state.srcBg = undefined;
      $state.srcVideo = undefined;
      $state.isOpen = false;
    },
  },
});

export const {
  openPlayerAction,
  closePlayerAction,
} = playerSlice.actions;

export default playerSlice.reducer;

import {
  IProductColor,
  IProductGroup,
  ProductAllParams,
  IProductTypes,
  GetProductListByCodeParams,
  GetProductRelatedParams,
} from './types';

import axiosInstance from 'services/common/instance';

export const apiGetAllProductList = async (params?: ProductAllParams):
  Promise<APIPaginationResponse<IProductV2[]>> => {
  const res = await axiosInstance.get('/api/v2/products', { params });
  return res.data;
};

export const apiGetAllProductListWithoutInventory = async (params?: ProductAllParams):
  Promise<APIPaginationResponse<IProductV2[]>> => {
  const res = await axiosInstance.get('/api/v2/products/all', { params });
  return res.data;
};

const apiGetProductDetail = async (id?: string): Promise<IProductV2> => {
  const res = await axiosInstance.get(`/api/v2/products/${id}/detail`);
  return res.data.data;
};

export const apiGetProductGroupList = async (): Promise<IProductGroup[]> => {
  const res = await axiosInstance.get('/api/v1/products/groups');
  return res.data.data;
};

export const apiGetProductColorsList = async (): Promise<IProductColor[]> => {
  const res = await axiosInstance.get('/api/v1/products/colors');
  return res.data.data;
};

export const apiGetProductTypesList = async (): Promise<IProductTypes[]> => {
  const res = await axiosInstance.get('/api/v1/products/types');
  return res.data.data;
};

export const apiGetProductViewed = async (): Promise<IProductV2[]> => {
  const res = await axiosInstance.get('/api/v2/products/viewed');
  return res.data.data;
};

export const apiGetProductPopular = async (): Promise<IProductV2[]> => {
  const res = await axiosInstance.get('/api/v2/products/popular');
  return res.data.data;
};

export const apiGetProductTopSales = async (): Promise<IProductV2[]> => {
  const res = await axiosInstance.get('/api/v2/products/top-sales');
  return res.data.data;
};

export const apiGetProductFavorite = async (): Promise<IProductV2[]> => {
  const res = await axiosInstance.get('/api/v2/products/favorite');
  return res.data.data;
};

export const apiGetProductBought = async (): Promise<IProductV2[]> => {
  const res = await axiosInstance.get('/api/v2/products/bought-products');
  return res.data.data;
};

export const apiGetProductListByCode = async (
  params?: GetProductListByCodeParams
): Promise<IProductV2[]> => {
  const res = await axiosInstance.get('/api/v2/products/list-by-codes', { params });
  return res.data.data;
};

export const apiGetProductInterested = async (
  params?: { limit?: number }
): Promise<IProductV2[]> => {
  const res = await axiosInstance.get('/api/v2/products/interested', { params });
  return res.data.data;
};

export const apiGetProductRelatedById = async (
  params?: GetProductRelatedParams
): Promise<IProductV2[]> => {
  const res = await axiosInstance.get(`/api/v2/products/${params?.id}/related`, { params: params?.meta });
  return res.data.data;
};

export default apiGetProductDetail;

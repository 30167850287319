/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from '..';

import { IBanner } from 'services/banner/types';

export const apiBanner = createApi({
  reducerPath: 'banner-rtk',
  baseQuery: axiosBaseQuery(),
  refetchOnMountOrArgChange: 60 * 60, // 1h api sẽ call lại khi mount
  endpoints: (builder) => ({
    getBanner: builder.query<APIPaginationResponse<IBanner[]>, { page?: string }>({
      query: (arg) => ({ url: '/api/v1/banner', method: 'get', params: arg })
    }),
  }),
});

import { createAsyncThunk } from '@reduxjs/toolkit';

import { apiGetCartsCurrent, apiGetCartsFuture, apiGetCartsV2 } from 'services/cart';
import { ICarts } from 'services/cart/types';
import { apiGetProductListByCode } from 'services/product';
import { GetProductListByCodeParams } from 'services/product/types';

export const getCartCurrentAsync = createAsyncThunk<ICarts, void, {}>('cart/current', async (_, { rejectWithValue }) => {
  try {
    const res = await apiGetCartsCurrent();
    return res;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getCartFutureAsync = createAsyncThunk<ICarts, void, {}>('cart/future', async (_, { rejectWithValue }) => {
  try {
    const res = await apiGetCartsFuture();
    return res;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getCartAsync = createAsyncThunk<ICartsV2, void, {}>('cart/cart', async (_, { rejectWithValue }) => {
  try {
    const res = await apiGetCartsV2();
    return res;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getProductListByCodeAsync = createAsyncThunk<IProductV2[], GetProductListByCodeParams, {}>('cart/listByCode', async (params, { rejectWithValue }) => {
  try {
    const res = await apiGetProductListByCode(params);
    return res;
  } catch (error) {
    return rejectWithValue(error);
  }
});

import { createSlice } from '@reduxjs/toolkit';

import { getListWholesaleAsync, getNewsAsync } from './thunk';

import { INews } from 'services/news/types';
import { IWholesaleCustomer } from 'services/wholesale/type';

interface State {
  list: INews[];
  listWholesale: IWholesaleCustomer[];
}

const initialState: State = {
  list: [],
  listWholesale: [],
};

const newsSlice = createSlice({
  name: 'newsReducer',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getNewsAsync.fulfilled, ($state, action) => {
      $state.list = action.payload.data;
    });
    builder.addCase(getListWholesaleAsync.fulfilled, ($state, action) => {
      $state.listWholesale = action.payload.data;
    });
  },
});

export default newsSlice.reducer;

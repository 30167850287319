import React from 'react';

import Container from 'components/organisms/Container';

interface HeaderTopPanelProps {
  leftContent: React.ReactNode;
  rightContent: React.ReactNode;
}

const HeaderTopPanel: React.FC<HeaderTopPanelProps> = ({
  leftContent,
  rightContent,
}) => (
  <div className="m-headerTopPanel">
    <Container>
      <div className="m-headerTopPanel_wrapper">
        {leftContent}
        {rightContent}
      </div>
    </Container>
  </div>
);

export default HeaderTopPanel;

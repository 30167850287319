import { LOCAL_PROMOTION } from 'utils/constants';

let accessTokenLocal = window.localStorage.getItem('token');
let accessTokenSession = window.sessionStorage.getItem('token');

/**
 * Listen for changes from other tabs
 */
window.addEventListener('storage', (event) => {
  if (event.key === 'token') {
    accessTokenLocal = event.newValue;
    accessTokenSession = event.newValue;
  }
});

export const getAccessToken = (): string | null => accessTokenLocal || accessTokenSession;

export const setAccessToken = (token: string, isLocal?: boolean): void => {
  if (isLocal) {
    accessTokenLocal = token;
    window.localStorage.setItem('token', token);
  } else {
    accessTokenSession = token;
    window.sessionStorage.setItem('token', token);
  }
};

export const clearLocalStorage = () => {
  window.localStorage.clear();
};

export const clearSessionStorage = () => {
  window.localStorage.clear();
};

export const cleanUpStorage = (): void => {
  accessTokenSession = null;
  accessTokenLocal = null;
  window.sessionStorage.removeItem('token');
  window.localStorage.removeItem('token');
  window.localStorage.removeItem(LOCAL_PROMOTION.name);
};

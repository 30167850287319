import {
  ILogin, IProfile, LoginPayload,
  ChangePasswordPayload, ProfilePayload, IAccount,
  VerifyResetPassPayload,
  ResetPasswordPayload,
  VerifyResetPassResponse,
  CheckResetPassResponse,
  RegisterPayload
} from './types';

import axiosInstance from 'services/common/instance';

export const apiGetProfile = async (): Promise<IProfile> => {
  const res = await axiosInstance.get('/api/v2/profile');
  return res.data.data;
};

export const apiDeleteProfile = async () => {
  const res = await axiosInstance.delete('api/v2/customers/request-delete');
  return res.data.data;
};

export const apiLoginByPhoneOrEmail = async (params: LoginPayload): Promise<ILogin> => {
  const resLogin = await axiosInstance.post('/api/v1/normal-login-phone-or-email', params);
  const res = await axiosInstance.get(
    '/api/v2/profile',
    {
      headers: {
        Authorization: `Bearer ${resLogin.data.data.access_token}`
      }
    }
    );
  resLogin.data.data.account_info = res.data.data;
  return resLogin.data.data;
};

export const apiLogout = async (params: Pick<LoginPayload, 'device_id'>): Promise<void> => {
  await axiosInstance.post('/api/v1/logout', params);
};

export const apiChangePassword = async (params: ChangePasswordPayload): Promise<void> => {
  await axiosInstance.patch('/api/v1/profile/change-password', params);
};

export const apiUpdateProfile = async (payload: ProfilePayload): Promise<IProfile> => {
  const res = await axiosInstance.post('/api/v2/profile', payload);
  return res.data.data;
};

export const apiRegister = async (params: RegisterPayload): Promise<IProfile> => {
  const res = await axiosInstance.post('/api/v1/register', params);
  return res.data.data;
};

export const apiCheckResetPass = async (payload: Pick<IAccount, 'phone'>): Promise<CheckResetPassResponse> => {
  const res = await axiosInstance.post('/api/v1/check-reset-password-otp', payload);
  return res.data.data;
};

export const apiVerifyResetPass = async (params: VerifyResetPassPayload)
  : Promise<VerifyResetPassResponse> => {
  const res = await axiosInstance.post('/api/v1/verify-reset-password-otp', params);
  return res.data.data;
};

export const apiResetPassword = async (params: ResetPasswordPayload): Promise<void> => {
  await axiosInstance.patch('/api/v1/reset-password-otp', params);
};

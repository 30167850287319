import { INotification, NotificationPayload } from './types';

import axiosInstance from 'services/common/instance';

export const apiGetNotifications = async (
  params: NotificationPayload
): Promise<APIPaginationResponse<INotification[]>> => {
  const res = await axiosInstance.get('/api/v1/notifications', { params });
  return res.data;
};

export const apiSeenNotification = async (id: string): Promise<INotification> => {
  const res = await axiosInstance.patch(`/api/v1/notification/${id}/seen`);
  return res.data.data;
};

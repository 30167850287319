import React, { useState, useRef } from 'react';

import Icon from 'components/atoms/Icon';
import ParagraphMain from 'components/atoms/Typography';
import useWindowScroll from 'hooks/useWindowScroll';
import mapModifiers from 'utils/functions';

interface FloatingPhoneProps {
}

const FloatingPhone: React.FC<FloatingPhoneProps> = () => {
  const [active, setActive] = useState<boolean>(false);
  const [isHover, setIsHover] = useState<boolean>(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  useWindowScroll(() => {
    if (window.scrollY < 200) {
      setActive(false);
    } else {
      setActive(true);
    }
  });

  const handleHoverIn = () => {
    if (wrapperRef.current) {
      wrapperRef.current.style.display = 'block';
    }
    setIsHover(true);
  };

  const handleHoverOut = () => {
    setIsHover(false);
    setTimeout(() => {
      if (wrapperRef.current) {
        wrapperRef.current.style.display = 'none';
      }
    }, 250);
  };

  return (
    <div
      className={`o-floatingPhone ${active ? 'active' : ''}`}
      onMouseEnter={handleHoverIn}
      onMouseLeave={handleHoverOut}

    >
      <div ref={wrapperRef} className={mapModifiers('o-floatingPhone_wrapper', isHover && 'active')}>
        <a href="tel:0587578678" rel="noreferrer">
          <div className="o-floatingPhone_content">
            <ParagraphMain.Paragraph type="div" modifiers={['14x20', '500', 'orangeBronze']}>0587578678</ParagraphMain.Paragraph>
          </div>
        </a>
      </div>
      <a href="tel:0587578678" rel="noreferrer">
        <div className="o-floatingPhone_button">
          <Icon iconName="headphoneWhite" size="24" />
        </div>
      </a>
    </div>
  );
};

export default FloatingPhone;

import React, { useEffect, useMemo, useState } from 'react';

import PopupDiscount from 'components/organisms/PopupDiscount';
import { useAsync } from 'hooks/useAsync';
import apiGetPromotions from 'services/Promotions';
import { IPromotion } from 'services/Promotions/type';
import { useAppSelector } from 'store/hooks';
import { LOCAL_PROMOTION } from 'utils/constants';
import { baseURL } from 'utils/functions';

const Promotions = () => {
  const profile = useAppSelector((state) => state.auth.profile);
  const isLogin = useMemo(() => !!profile, [profile]);
  const [data, setData] = useState<IPromotion[]>();

  const [execute] = useAsync(apiGetPromotions, {
    onSuccess: (res) => {
      setData(res);
      window.localStorage.setItem(LOCAL_PROMOTION.name, LOCAL_PROMOTION.value);
    }
  });

  useEffect(() => {
    if (isLogin && window.localStorage.getItem(LOCAL_PROMOTION.name) !== LOCAL_PROMOTION.value) {
      execute();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogin]);

  const promotions = useMemo(() => data?.map(
    (v) => ({
      name: v.name,
      code: v.promotion_code,
      describe: v.description,
      slug: v.video_url,
      srcImage: v.video_thumbnail ? baseURL(v.video_thumbnail) : '',
      src: v.video_path ? baseURL(v.video_path) : ''
    })
  ) || [], [data]);

  if (promotions.length <= 0) {
    return null;
  }

  return (
    <PopupDiscount
      isOpen={promotions.length > 0}
      handleClose={() => {
        setData(undefined);
      }}
      data={promotions}
    />
  );
};

export default Promotions;

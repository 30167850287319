import React from 'react';

import Account from './Account';
import HeaderBottom from './HeaderBottom';
import Notification from './Notification';
import TitlePage from './TitlePage';

import { LinkProps } from 'components/atoms/Link';
import Typography from 'components/atoms/Typography';
import HeaderTopPanel from 'components/organisms/Header/HeaderTopPanel';

export interface NavItemProps extends LinkProps {
  label?: React.ReactNode;
}

interface HeaderProps {
  titlePage?: string;
  button?: React.ReactNode;
}

const Header: React.FC<HeaderProps> = ({
  titlePage,
  button
}) => (
  <header className="o-header">
    <HeaderTopPanel
      leftContent={<Typography.Paragraph content="Chào mừng bạn đã đến với website của chúng tôi!" modifiers={['14x20', 'eerieBlack']} />}
      rightContent={(
        <div className="o-header_topActions">
          <Notification />
          <Account />
        </div>
      )}
    />
    {titlePage ? <TitlePage title={titlePage} button={button} /> : <HeaderBottom />}
  </header>
);

export default Header;

import { baseURL } from 'utils/functions';

function youtubeParser(url: string) {
  // eslint-disable-next-line no-useless-escape
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
  const match = url.match(regExp);
  // eslint-disable-next-line eqeqeq
  return match && match[7].length == 11 ? match[7] : false;
}

function youtubeControlIframe(url: string, autoPlay?: boolean) {
  return `<iframe src="https://www.youtube.com/embed/${youtubeParser(
    url,
  )}?enablejsapi=1&rel=0&autoplay=${autoPlay ? 1 : 0}&mute=1&disablekb=1&enablejsapi=1&loop=1&modestbranding=1&playsinline=1&color=white" frameborder="0" allowfullscreen allow="autoplay"></iframe>`;
}

function convertSrcVideo(src?: string, autoPlayYoutube?: boolean) {
  if (!src) return '';
  if (youtubeParser(src)) return youtubeControlIframe(src, autoPlayYoutube);
  if (src.includes('http')) return src;
  return baseURL(src);
}

const fn = {
  youtubeParser,
  youtubeControlIframe,
  convertSrcVideo
};

export default fn;

import dayjs from 'dayjs';

export const LOCAL_PROMOTION = {
  name: 'popup-promotion',
  value: dayjs().format('DD/MM/YYYY')
};

export const STATUS_ORDER = {
  NEW: 1,
  CONFIRMED: 3, //
  APPROVED: 4,
  DELIVERING: 5, //
  COMPLETED: 7, //
  DRAFT: 9, //
  DELETED: 10, //
  CANCELED: 11,
  EXPIRED: 999,
  ABOUT_TO_EXPIRE: 888,
};

export const STATUS_EXPIRE = {
  EXPIRED: 'expired',
  SOON: 'expiring_soon'
};

export type ListStatusOrderItemType = {
  label: string,
  value?: number,
  color?: ColorStyle,
  expiresStatus?: string,
};

export const LIST_STATUS_ORDER: Array<ListStatusOrderItemType> = [
  {
    label: 'Tất cả đơn hàng',
  },
  {
    label: 'Nháp',
    value: STATUS_ORDER.DRAFT,
    color: 'grayX11'
  },
  {
    label: 'Đã đặt',
    value: STATUS_ORDER.NEW,
    color: 'jet',
  },
  {
    label: 'Đang xử lý',
    value: STATUS_ORDER.CONFIRMED,
    color: 'sangria'
  },
  {
    label: 'Đã xác nhận',
    value: STATUS_ORDER.APPROVED,
    color: 'goGreen'
  },
  {
    label: 'Đang giao hàng',
    value: STATUS_ORDER.DELIVERING,
    color: 'orangeBronze',
  },
  {
    label: 'Hoàn thành',
    value: STATUS_ORDER.COMPLETED,
    color: 'goGreen'
  },
  {
    label: 'Đã xóa',
    value: STATUS_ORDER.DELETED,
    color: 'redPelati'
  },
  {
    label: 'Sắp đến hạn',
    value: STATUS_ORDER.ABOUT_TO_EXPIRE,
    color: 'redPelati',
    expiresStatus: 'expiring_soon'
  },
  {
    label: 'Quá hạn',
    value: STATUS_ORDER.EXPIRED,
    color: 'darkTangerine',
    expiresStatus: 'expired'
  },
  {
    label: 'Đã hủy',
    value: STATUS_ORDER.CANCELED,
    color: 'rossoCorsa'
  },
];

const CANCEL = [
  STATUS_ORDER.APPROVED,
  STATUS_ORDER.NEW,
  STATUS_ORDER.ABOUT_TO_EXPIRE,
  STATUS_ORDER.EXPIRED
];
const DELETE = [STATUS_ORDER.DRAFT];
const NEXT = [STATUS_ORDER.DRAFT];

const ORDER = [
  STATUS_ORDER.APPROVED,
  STATUS_ORDER.NEW,
  STATUS_ORDER.CONFIRMED,
  STATUS_ORDER.DELIVERING,
  STATUS_ORDER.COMPLETED,
  STATUS_ORDER.CANCELED,
  STATUS_ORDER.ABOUT_TO_EXPIRE,
  STATUS_ORDER.EXPIRED,
];
const RESTORE = [STATUS_ORDER.DELETED];
const SEE_DETAIL = [
  STATUS_ORDER.APPROVED,
  STATUS_ORDER.NEW,
  STATUS_ORDER.CONFIRMED,
  STATUS_ORDER.DELIVERING,
  STATUS_ORDER.COMPLETED,
  STATUS_ORDER.DELETED,
  STATUS_ORDER.CANCELED,
  STATUS_ORDER.ABOUT_TO_EXPIRE,
  STATUS_ORDER.EXPIRED,
];

export const BUTTON_TYPES = {
  DELETE,
  ORDER,
  CANCEL,
  NEXT,
  RESTORE,
  SEE_DETAIL
};

export const PROMOTION_CONJUNCTION = {
  OR: 'or',
  AND: 'and'
};

import React, {
  useContext, useEffect, useLayoutEffect, useState
} from 'react';

import ButtonSticky from './buttonSticky';
import { LayoutContext } from './context';
import Player from './player';

import Image from 'components/atoms/Image';
import Loading from 'components/atoms/Loading';
import Footer from 'components/organisms/Footer';
import Header from 'components/organisms/Header';
import mapModifiers from 'utils/functions';

interface LayoutProps {
  children?: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const { titlePage, loading, button } = useContext(LayoutContext);
  const [node, setNode] = useState<HTMLDivElement | null>(null);
  const [info, setInfo] = useState<{
    img?: string; quantity?: string; style?: React.CSSProperties
  }>();

  useLayoutEffect(() => {
    const handleAddCart = (e: any) => {
      if (e.detail?.e?.clientX && e.detail?.e?.clientY) {
        setInfo({
          img: e?.detail?.img,
          quantity: e?.detail?.quantity,
          style: {
            right: window.innerWidth - 20 - Number(e.detail?.e?.clientX || 0),
            bottom: window.innerHeight - 20 - Number(e.detail?.e?.clientY || 0),
          }
        });
      }
    };
    window.addEventListener('addCart', handleAddCart);
    return () => {
      window.removeEventListener('addCart', handleAddCart);
    };
  }, []);

  useEffect(() => {
    if (node) {
      setTimeout(() => {
        node.style.right = '30px';
        node.style.bottom = '150px';
      }, 100);
    }
  }, [node]);

  return (
    <main className={mapModifiers('t-layout', titlePage && 'personal')}>
      <Header
        titlePage={titlePage}
        button={button}
      />
      <div className="t-layout_content u-pb-40 u-pt-40">
        {children}
      </div>
      {loading && <Loading />}
      <ButtonSticky />
      <Player />
      <Footer />
      {info && (
        <div
          ref={(ref) => setNode(ref)}
          className="badge-info"
          style={info.style}
          onTransitionEnd={() => {
            setTimeout(() => {
              setInfo(undefined);
            }, 200);
          }}
        >
          <div className="badge-info_quantity">
            {info.quantity && Number(info.quantity) > 9 ? '9+' : info.quantity}
          </div>
          <Image src={info?.img} ratio="1x1" />
        </div>
      )}
    </main>
  );
};

Layout.defaultProps = {
  children: undefined,
};

export default Layout;

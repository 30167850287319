const COMMON_PATHS = {
  CART: '/gio-hang',
  CHECKOUT: '/thanh-toan',
};

const PATHS = {
  HOME: '/',
  ALL_PRODUCT: '/tat-ca-san-pham',
  PRODUCT_DETAIL: '/chi-tiet-san-pham/:slug',
  PRODUCT_DETAIL_WITH_PARAMS: (id: number | string) => `/chi-tiet-san-pham/${id}`,
  ACCOUNT_INFORMATION: '/thong-tin-tai-khoan',
  ACCOUNT_ORDER_MANAGEMENT: '/quan-ly-don-hang',
  ACCOUNT_ORDER_FUTURE: '/don-hang-tuong-lai',
  ORDER_SUCCESS: '/dat-hang-thanh-cong/:slug',
  ORDER_SUCCESS_WITH_PARAMS: (id: string) => `/dat-hang-thanh-cong/${id}`,
  CART: COMMON_PATHS.CART,
  STACK_CART: `${COMMON_PATHS.CART}/*`,
  STACK_CART_CHECKOUT: COMMON_PATHS.CHECKOUT,
  CHECKOUT: `${COMMON_PATHS.CART}${COMMON_PATHS.CHECKOUT}`,
  FLOWER_CARE: '/cham-soc-hoa',
  FLOWER_CARE_CATEGORY: '/cham-soc-hoa/:slug',
  FLOWER_CARE_CATEGORY_WITH_PARAMS: (slug: string) => `/cham-soc-hoa/${slug}`,
  FLOWER_CARE_DETAIL: '/cham-soc-hoa-chi-tiet/:slug',
  FLOWER_CARE_DETAIL_WITH_PARAMS: (slug: string) => `/cham-soc-hoa-chi-tiet/${slug}`,
  NEWS_WHOLESALE_CUSTOMERS: '/tin-tuc-khach-hang-si',
  NEWS_PROMOTIONS: '/khuyen-mai',
  NEWS_PROMOTIONS_CATEGORY: '/khuyen-mai/:slug',
  NEWS_PROMOTIONS_CATEGORY_WITH_PARAMS: (slug: string) => `/khuyen-mai/${slug}`,
  PRODUCT_VIEWS: '/san-pham-da-xem',
  PRODUCT_FAVORITE: '/san-pham-yeu-thich',
  PRODUCT_BOUGHT: '/san-pham-da-mua',
  LIBRARY: '/thu-vien',
  LOGIN: '/dang-nhap',
  NEW_DETAIL: '/tin-tuc/:slug',
  NEW_DETAIL_WITH_PARAMS: (slug: string) => `/tin-tuc/${slug}`,
  DETAIL_COURSE: '/chi-tiet-khoa-hoc/:slug',
  DETAIL_COURSE_WITH_PARAMS: (slug: string) => `/chi-tiet-khoa-hoc/${slug}`,
  PHASE_DETAIL_COURSE: '/chi-tiet-khoa-hoc/:slug/:phase',
  PHASE_DETAIL_COURSE_WITH_PARAMS: (slug: string, phase: string) => `/chi-tiet-khoa-hoc/${slug}/${phase}`,
  WHOLESALE_CATEGORY: '/tin-tuc-khach-hang-si/:slug',
  WHOLESALE_CATEGORY_WITH_PARAMS: (slug: string) => `/tin-tuc-khach-hang-si/${slug}`,
  WHOLESALE_CUSTOMER_DETAIL: '/tin-tuc-khach-hang-si-chi-tiet/:slug',
  WHOLESALE_CUSTOMER_DETAIL_WITH_PARAMS: (slug: string) => `/tin-tuc-khach-hang-si-chi-tiet/${slug}`,
  ORDER_SCHEDULE: '/lich-dat-hang',
  ORDER_LIST: '/danh-sach-don-hang',
  ORDER_DETAIL: '/chi-tiet-don-hang/:slug',
  ORDER_DETAIL_WITH_PARAMS: (slug: string) => `/chi-tiet-don-hang/${slug}`,
  CONFIRM: '/xac-nhan-don-hang/:slug',
  CONFIRM_WITH_PARAMS: (slug: string) => `/xac-nhan-don-hang/${slug}`,
};

export default PATHS;

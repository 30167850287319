import { IPromotion } from './type';

import axiosInstance from 'services/common/instance';

const apiGetPromotions = async (): Promise<IPromotion[]> => {
  const res = await axiosInstance.get('/api/v1/popup-promotions');
  return res.data.data;
};

export default apiGetPromotions;

import React from 'react';

import Icon, { IconName } from 'components/atoms/Icon';
import Typography from 'components/atoms/Typography';

interface BadgeItemProps {
  text: string;
  iconName: IconName;
  badgeNumber?: number;
  onClick?: () => void;
}

const BadgeItem: React.FC<BadgeItemProps> = ({
  text, iconName, badgeNumber, onClick
}) => (
  <button type="button" className="m-badgeItem" onClick={onClick}>
    <div className="m-badgeItem_icon">
      <Icon iconName={iconName} size="24" />
      {badgeNumber ? (
        <div className="m-badgeItem_badgeNumber">
          {badgeNumber > 9 ? '9+' : badgeNumber}
        </div>
      ) : ''}
    </div>
    <Typography.Paragraph type="div" modifiers={['14x20', '500', 'eerieBlack']} content={text} />
  </button>
);

export default BadgeItem;

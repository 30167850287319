import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { getNotificationsAsync, seenNotificationsAsync } from './thunk';
import State from './types';

export const builderNotificationList = (builder: ActionReducerMapBuilder<State>) => {
  builder.addCase(getNotificationsAsync.pending, ($state) => {
    $state.loading = true;
  });
  builder.addCase(getNotificationsAsync.fulfilled, ($state, { payload: { meta, data } }) => {
    $state.meta = meta;
    $state.list = meta.page === 1 ? data : $state.list.concat(data);
    $state.loading = false;
  });
  builder.addCase(getNotificationsAsync.rejected, ($state) => {
    $state.loading = false;
  });
};

export const builderSeenNotification = (builder: ActionReducerMapBuilder<State>) => {
  builder.addCase(seenNotificationsAsync.fulfilled, ($state, action) => {
    if ($state.list) {
      const index: number = $state.list.findIndex((item) => item.id === action.payload.id);
      if (index !== -1) {
        $state.list.splice(index, 1, action.payload);
      }
    }
  });
};

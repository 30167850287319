import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import {
  getCartAsync,
  getProductListByCodeAsync,
} from './thunk';
import State from './types';

export const builderCart = (builder: ActionReducerMapBuilder<State>) => {
  builder.addCase(getCartAsync.fulfilled, ($state, action) => {
    $state.cart = {
       ...action.payload,
        cart_items: action.payload?.cart_items?.map((x) => ({ ...x, checked: true }))
      };
  });
};

export const builderListProductByCode = (builder: ActionReducerMapBuilder<State>) => {
  builder.addCase(getProductListByCodeAsync.fulfilled, ($state, action) => {
    if ($state.cart?.cart_items) {
      $state.cart.cart_items = $state.cart.cart_items.map(
        (x) => ({ ...x, info: action.payload.find((m) => m.code === x.code) })
        );
    }
  });
};

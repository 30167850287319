import React from 'react';

import mapModifiers from 'utils/functions';

export const iconList = {
  heart: 'heart',
  minusGray: 'minusGray',
  minusGreen: 'minusGreen',
  plusGreen: 'plusGreen',
  close: 'close',
  arrowLeftGray: 'arrowLeftGray',
  arrowLeftOrange: 'arrowLeftOrange',
  arrowRightGray: 'arrowRightGray',
  arrowRightOrange: 'arrowRightOrange',
  arrowLeftWhite: 'arrowLeftWhite',
  arrowRightWhite: 'arrowRightWhite',
  heartWhite: 'heartWhite',
  plusWhite: 'plusWhite',
  loadingWhite: 'loadingWhite',
  loadingGray: 'loadingGray',
  arrowOrange: 'arrowOrange',
  prevPaginate: 'prevPaginate',
  nextPaginate: 'nextPaginate',
  heartGreen: 'heartGreen',
  shoppingOrange: 'shoppingOrange',
  shoppingCartGreen: 'shoppingCartGreen',
  notifyGray: 'notifyGray',
  userGray: 'userGray',
  notepad: 'notepad',
  person: 'person',
  clipboardText: 'clipboardText',
  eye: 'eye',
  heartGray: 'heartGray',
  power: 'power',
  closeGray: 'closeGray',
  dismissCircle: 'dismissCircle',
  checkCircle: 'checkCircle',
  shoppingCartWhite: 'shoppingCartWhite',
  cartBagWhite: 'cartBagWhite',
  headphoneWhite: 'headphoneWhite',
  loadingGreen: 'loadingGreen',
  loadingJet: 'loadingJet',
  gridGreen: 'gridGreen',
  gridGray: 'gridGray',
  listGreen: 'listGreen',
  listGray: 'listGray',
  arrowRightGreen: 'arrowRightGreen',
  facebook: 'facebook',
  instagram: 'instagram',
  youtube: 'youtube',
  navigationWhite: 'navigationWhite',
  closeCircle: 'closeCircle',
  playWhite: 'playWhite',
  starOrangeOutline: 'starOrangeOutline',
  starOrange: 'starOrange',
  playWhiteCircle: 'playWhiteCircle',
  downBlack: 'downBlack',
  playGraySmall: 'playGraySmall',
  playBlueSmall: 'playBlueSmall',
  closeCircleBlack: 'closeCircleBlack',
  fullscreen: 'fullscreen',
  cancelFullscreen: 'cancelFullscreen',
  controlPause: 'controlPause',
  controlPlay: 'controlPlay',
  gridByFiveGray: 'gridByFiveGray',
  gridByFiveGreen: 'gridByFiveGreen',
  gridByThreeGray: 'gridByThreeGray',
  gridByThreeGreen: 'gridByThreeGreen',
  buildingGreen: 'buildingGreen',
  mapGreen: 'mapGreen',
  phoneGreen: 'phoneGreen',
  locationGreen: 'locationGreen',
  mailGreen: 'mailGreen',
  lockGreen: 'lockGreen',
  personGreen: 'personGreen',
  eyeBlack: 'eyeBlack',
  eyeBlackOff: 'eyeBlackOff',
  arrowLeftBlack: 'arrowLeftBlack',
  listBlue: 'listBlue',
  arrowLeftBlackDeep: 'arrowLeftBlackDeep',
  arrowRightBlackDeep: 'arrowRightBlackDeep',
  nounAlert: 'nounAlert',
  gift: 'gift',
  addCircle: 'addCircle',
  news: 'news',
  arrowRightBlue: 'arrowRightBlue',
  voucher: 'voucher',
  delete: 'delete',
  popupDelete: 'popupDelete',
  voucher1: 'voucher1',
  sale: 'sale',
  gif: 'gif',
  nounAlertBlack: 'nounAlertBlack',
  tooltipArrow: 'tooltipArrow',
  notification: 'notification',
};

export type IconName = keyof typeof iconList;

export type IconSize = '12' | '14' | '16' | '20' | '22x14' | '22' | '24' | '26' | '28' | '32' | '33' | '35' | '36' | '40' | '60';

interface IconProps {
  iconName: IconName;
  size?: IconSize;
}
const Icon: React.FC<IconProps> = ({ iconName, size }) => (
  <i className={mapModifiers('a-icon', iconName, size)} />);

Icon.defaultProps = {
  size: '16',
};

export default Icon;

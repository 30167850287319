import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import getOrderDiscountAsync from './thunk';
import State from './types';

const builderOrderDiscountGroups = (builder: ActionReducerMapBuilder<State>) => {
  builder.addCase(getOrderDiscountAsync.fulfilled, ($state, action) => {
    $state.listOrderDiscount = action.payload;
  });
};
export default builderOrderDiscountGroups;

import React, { useCallback, useMemo, useState } from 'react';

import Icon, { IconName } from 'components/atoms/Icon';
import mapModifiers from 'utils/functions';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: string;
  type?: 'text' | 'password' | 'number';
  id?: string;
  isSearch?: boolean;
  textSearch?: string;
  handleClick?: () => void;
  iconName?: IconName;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({
    iconName, label, error, type = 'text', id, isSearch, textSearch = 'Tìm kiếm', handleClick, ...rest
  }, ref) => {
    const [showPassword, setShowPassword] = useState(false);
    const derivedType = useMemo(() => {
      if (type === 'password') {
        return showPassword ? 'text' : 'password';
      }
      return type;
    }, [type, showPassword]);

    const togglePassword = useCallback(() => {
      setShowPassword(!showPassword);
    }, [showPassword]);

    return (
      <div
        className={mapModifiers(
          'a-input',
          error && 'error',
          isSearch && 'search',
        )}
      >
        {label && (
          <label className="a-input_label" htmlFor={id}>
            {label}
          </label>
        )}
        <div className="a-input_wrapper">
          <div className="a-input_input">
            {iconName && (
              <div className="a-input_icon">
                <Icon iconName={iconName} size="20" />
              </div>
            )}
            <input id={id} type={derivedType} ref={ref} {...rest} />
            {type === 'password'
              && (
                <div className="a-input_eye" onClick={togglePassword}>
                  <Icon iconName={showPassword ? 'eyeBlackOff' : 'eyeBlack'} size="20" />
                </div>
              )}
          </div>
          {isSearch && <div onClick={handleClick} className="a-input_button">{textSearch}</div>}
        </div>
        {error && <div className="a-input_error">{error}</div>}
      </div>
    );
  },
);

export default Input;

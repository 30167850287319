import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import {
  getIsCloseInventoriesAsync, getSystemConfigAsync,
} from './thunk';
import State from './types';

export const builderGetIsCloseInventories = (builder: ActionReducerMapBuilder<State>) => {
  builder.addCase(getIsCloseInventoriesAsync.fulfilled, ($state, action) => {
    $state.closeStore = action.payload;
  });
};

export const builderGetSystemConfig = (builder: ActionReducerMapBuilder<State>) => {
  builder.addCase(getSystemConfigAsync.fulfilled, ($state, action) => {
    $state.config = action.payload;
  });
};

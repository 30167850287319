import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import {
  BrowserRouter as Router, Navigate, Route, Routes, useNavigate,
} from 'react-router-dom';

import Initial from 'common/Initial';
import Button from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';
import { Toastify } from 'components/atoms/Toastify';
import Typography from 'components/atoms/Typography';
import DownloadPopup from 'components/organisms/DownloadPopup';
import CustomModal from 'components/organisms/Modal';
import LayoutProvider from 'components/templates/Layout/context';
import PrivateRouterRoot from 'helpers/privateRouterRoot';
import routes from 'routes';
import { store } from 'store';
import './App.scss';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { changeModalLogin } from 'store/system';
import PATHS from 'utils/paths';

const App: React.FC = () => {
  const { modalLogin } = useAppSelector((state) => state.system);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  return (
    <>
      <Routes>
        {routes.map((route, i) => (
          <Route
            key={`route-${i.toString()}`}
            path={route.path}
            element={<Suspense fallback={null}><PrivateRouterRoot {...route} /></Suspense>}
          />
        ))}
        <Route path="*" element={<Navigate to={PATHS.HOME} replace />} />
      </Routes>
      <CustomModal
        isOpen={modalLogin?.isOpenLogin}
        modifiers="profile"
        hasIconClose={false}
      >
        <div className="u-pb-20 d-flex justify-content-center">
          <Icon iconName="notification" size="60" />
        </div>
        <Typography.Title
          type="h5"
          modifiers={['600', 'eerieBlack', 'center']}
          content="Thông báo"
        />
        <div className="u-mt-8 u-mb-20">
          <Typography.Paragraph modifiers={['16x24', '400', 'dimGray', 'center']} content="Bạn cần đăng nhập để sử dụng chức năng này." />
        </div>
        <div className="u-mt-20 d-flex justify-content-center">
          <div>
            <Button
              modifiers={[
                'md',
                'bg-isabelline2',
                'text-jet'
              ]}
              onClick={() => dispatch(changeModalLogin({
                isOpenLogin: false,
                redirectTo: undefined
              }))}
            >
              Huỷ
            </Button>
          </div>
          <div className="u-ml-20">
            <Button
              modifiers={[
                'bg-northTexasGreen',
                'md',
                'text-white',
              ]}
              type="button"
              onClick={() => {
                navigate({
                  pathname: PATHS.LOGIN,
                  search: modalLogin?.redirectTo ? `?redirect=${modalLogin.redirectTo}` : undefined
                });
                dispatch(changeModalLogin({
                  ...modalLogin,
                  isOpenLogin: false,
                }));
              }}
            >
              Đăng nhập
            </Button>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

const AppWrapper: React.FC = () => (
  <Provider store={store}>
    <Router>
      <DownloadPopup />
      <LayoutProvider>
        <App />
      </LayoutProvider>
      <Toastify />
      <Initial />
    </Router>
  </Provider>
);

export default AppWrapper;

import React from 'react';

import mapModifiers from 'utils/functions';

export type SizeImageType = 'cover' | 'contain' | 'inherit' | 'initial';

export type ImageProps = {
  src?: string;
  alt?: string;
  ratio?: Ratio;
  size?: SizeImageType;
  isLoading?: boolean;
};

const Image: React.FC<ImageProps> = ({
  src = '',
  alt = 'replacing',
  ratio = '1x1',
  size = 'cover',
  isLoading,
}) => (
  <div className={mapModifiers('a-image', ratio, size)}>
    <img src={src} alt={alt} loading={isLoading ? 'lazy' : undefined} />
  </div>
);

export default Image;

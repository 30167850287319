import React from 'react';
import {
  ToastContainer, toast as toastInstance, Slide,
} from 'react-toastify';

import Typography from '../Typography';

import Icon from 'components/atoms/Icon';
import mapModifiers from 'utils/functions';

type Type = 'success' | 'error';

export interface ToastifyMessageProps {
  message?: string;
  type: Type;
}

export const ToastifyMessage: React.FC<ToastifyMessageProps> = ({
  type,
  message,
}) => (
  <div className={mapModifiers('a-toastify_content', type)}>
    <div className="a-toastify_icon">
      <Icon size="24" iconName={type === 'error' ? 'dismissCircle' : 'checkCircle'} />
    </div>
    <div className="a-toastify_message">
      <Typography.Paragraph modifiers={['16x22', '600', 'white']} content={message} />
    </div>
  </div>
);

let currentToastId: React.ReactText | null;

export const ToastSingleMode = ({ ...props }: ToastifyMessageProps) => {
  if (currentToastId) {
    toastInstance.dismiss(currentToastId);
  }
  currentToastId = toastInstance(
    <ToastifyMessage
      {...props}
    />,
  );

  return currentToastId;
};

export const toast = toastInstance;

export const Toastify: React.FC = () => (
  <div className="a-toastify">
    <ToastContainer
      hideProgressBar
      transition={Slide}
      position="bottom-right"
      autoClose={1500}
      closeOnClick={false}
      pauseOnHover
      draggable={false}
      closeButton={false}
    />
  </div>
);

import React, { useMemo, useState } from 'react';

import Button from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';
import Image from 'components/atoms/Image';
import ParagraphMain from 'components/atoms/Typography';
import CustomModal from 'components/organisms/Modal';
import Player from 'components/organisms/Player';

interface Voucher {
  name?: string,
  code?: string,
  describe?: string,
  contain?: string,
  slug?: string,
  srcImage?: string,
  src?: string
}
interface PopupDiscountProps {
  data: Voucher[];
  isOpen?: boolean;
  handleClose?: () => void;
}

const PopupDiscount: React.FC<PopupDiscountProps> = ({
  data, isOpen, handleClose,
}) => {
  const [index, setIndex] = useState(0);
  const select = useMemo(() => data.find((_, i) => i === index), [data, index]);

  const handleNext = () => {
    if (index < data.length - 1) setIndex((state) => state + 1);
  };

  const handlePre = () => {
    if (index > 0) setIndex((state) => state - 1);
  };

  return (
    <CustomModal
      handleClose={handleClose}
      isOpen={isOpen}
      modifiers="promotion"
      icon={{
        name: 'close'
      }}
    >
      <div className="o-popupDiscount">

        <div className="o-popupDiscount_img">
          {select?.src ? (
            <Player
              poster={select?.srcImage}
              controls
              src={select.src}
              ratio="1x1"
            />
          ) : <Image src={select?.srcImage} alt={select?.name} />}
        </div>

        <div className="o-popupDiscount_container">
          <div className="o-popupDiscount_container_header">
            <ParagraphMain.Title
              type="h5"
              modifiers={['600', 'northTexasGreen', 'center']}
              content={select?.name}
            />
          </div>
          <div className="u-mt-12">
            <ParagraphMain.Paragraph
              modifiers={['14x20', 'auroMetaSaurus']}
              content={select?.describe}
            />
          </div>

          {select?.code && (
            <div className="o-popupDiscount_container_code u-mt-20">
              <ParagraphMain.Paragraph
                modifiers={['16x24', 'raisinBlack', 'center', 'uppercase']}
                content={select.code}
              />
            </div>
          )}

          <div className="o-popupDiscount_container_action u-mt-20">
            <div className="o-popupDiscount_container_btn">
              <Button
                modifiers={['md', 'border-orangeBronze', 'bg-white', 'text-raisinBlack']}
                onClick={handleClose}
              >
                NO THANKS!
              </Button>
            </div>
            <div className="o-popupDiscount_container_btn">
              <Button
                modifiers={['md', 'bg-orangeBronze', 'text-white']}
                href={select?.slug}
              >
                SHOP NOW
              </Button>
            </div>
          </div>

          {data.length > 1 && (
            <div className="o-popupDiscount_container_nav u-mt-20">
              <button
                type="button"
                className="o-popupDiscount_container_nav_btn"
                onClick={handlePre}
              >
                <Icon iconName="arrowLeftBlackDeep" size="16" />
              </button>
              <div className="u-ml-24 u-mr-24">
                <ParagraphMain.Paragraph
                  type="span"
                  modifiers={['24x32', 'darkJungleGreen', '600']}
                  content={`${index + 1} `}
                />
                <ParagraphMain.Paragraph
                  type="span"
                  modifiers={['14x20', 'darkJungleGreen', '400']}
                  content={`/${data.length}`}
                />
              </div>
              <button
                type="button"
                className="o-popupDiscount_container_nav_btn"
                onClick={handleNext}
              >
                <Icon iconName="arrowRightBlackDeep" size="16" />
              </button>
            </div>
          )}
        </div>
      </div>
    </CustomModal>
  );
};

PopupDiscount.defaultProps = {
};

export default PopupDiscount;

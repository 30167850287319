import React from 'react';

import FUNCTIONS from './functions';

import mapModifiers from 'utils/functions';

export interface PlayerProps extends React.AllHTMLAttributes<HTMLVideoElement> {
  ratio?: '16x9' | '1x1' | '741x416';
  autoPlayYoutube?: boolean;
}

const Player = React.forwardRef<HTMLVideoElement, PlayerProps>(({
  src,
  ratio,
  autoPlayYoutube,
  ...props
}, ref) => {
  if (!src) return <div />;

  if (FUNCTIONS.youtubeParser(src)) {
    return (
      <div
        className={mapModifiers('o-player', ratio)}
      >
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: FUNCTIONS.convertSrcVideo(src, autoPlayYoutube) }}
        />
      </div>
    );
  }

  return (
    <div className={mapModifiers('o-player', ratio)}>
      <video
        {...props}
        ref={ref}
        playsInline
      >
        <source
          src={FUNCTIONS.convertSrcVideo(src)}
          type="video/mp4"
        />
        Trình duyệt của bạn không hỗ trợ phát video,
        vui lòng sử dụng trình duyệt khác. Xin cảm ơn.
        <track kind="captions" />
      </video>
    </div>
  );
});

Player.defaultProps = {
  ratio: '16x9'
};

export default Player;

import React, {
  useCallback, useMemo, useState
} from 'react';

import Dropdown from '../Dropdown';

import Icon from 'components/atoms/Icon';
import Typography from 'components/atoms/Typography';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getNotificationsAsync, seenNotificationsAsync } from 'store/notification/thunk';
import mapModifiers from 'utils/functions';

const Notification: React.FC = () => {
  const dispatch = useAppDispatch();
  const userInfo = useAppSelector((state) => state.auth.profile);
  const { loading, list, meta } = useAppSelector((state) => state.notification);
  const [open, setOpen] = useState(false);

  const handleSeen = useCallback((id: string) => {
    dispatch(seenNotificationsAsync(id));
  }, [dispatch]);

  const notificationData = useMemo(() => list.map((item) => ({
    title: item?.data?.title,
    status: item?.data?.content,
    isSeen: !!item.read_at,
    handleSeen: () => handleSeen(item.id)
  })), [list, handleSeen]);

  const handleOpen = () => {
    setOpen(!open);
    if (!open && !loading && !meta) {
      dispatch(getNotificationsAsync({ page: 1, limit: 10 }));
    }
  };

  const handleLoadMore = useCallback(() => {
    if (meta && meta.page < meta.totalPages && !loading) {
      dispatch(getNotificationsAsync({ page: meta.page + 1, limit: 10 }));
    }
  }, [dispatch, loading, meta]);

  if (!userInfo) return null;

  return (
    <Dropdown.Wrap
      variant="notify"
      content={(
        <Dropdown.Notify
          onLoadMore={handleLoadMore}
          list={notificationData}
          loading={loading}
        />
      )}
      isOpen={open}
      handleClose={() => setOpen(false)}
    >
      <div onClick={handleOpen} className={mapModifiers('o-header_topActions_actionItem', 'notify')}>
        <Icon iconName="notifyGray" size="16" />
        <Typography.Paragraph type="div" content="Thông báo" modifiers={['14x20', '500', 'eerieBlack']} />
      </div>
    </Dropdown.Wrap>
  );
};

export default Notification;

import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { LayoutContext } from 'components/templates/Layout/context';
import { apiLogout } from 'services/auth';
import { cleanUpStorage } from 'services/common/storage';
import { resetAction, store } from 'store';
import PATHS from 'utils/paths';

const useLogout = () => {
  const navigate = useNavigate();
  const { setLoading } = useContext(LayoutContext);
  const execute = async () => {
    try {
      if (setLoading) {
        setLoading(true);
      }
      await apiLogout({
        device_id: ''
      });
    } finally {
      if (setLoading) {
        setLoading(false);
      }
      navigate(PATHS.NEWS_PROMOTIONS);
      cleanUpStorage();
      setTimeout(() => {
        store.dispatch(resetAction());
      }, 200);
    }
  };

  return execute;
};

export default useLogout;

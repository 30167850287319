import { createAsyncThunk } from '@reduxjs/toolkit';

import apiGetIsCloseInventories from 'services/inventories';
import { apiGetSystemConfig } from 'services/system';

export const getIsCloseInventoriesAsync = createAsyncThunk('system/getIsCloseInventories', async (_, { rejectWithValue }) => {
  try {
    return await apiGetIsCloseInventories();
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSystemConfigAsync = createAsyncThunk('system/getSystemConfig', async (_, { rejectWithValue }) => {
  try {
    return await apiGetSystemConfig();
  } catch (error) {
    return rejectWithValue(error);
  }
});

import { createSlice } from '@reduxjs/toolkit';

import builderOrderDiscountGroups from './action';
import State from './types';

const initialState: State = {
  listOrderDiscount: undefined,
};

const ordersSlice = createSlice({
  name: 'orderDiscountReducer',
  initialState,
  reducers: {

  },
  extraReducers(builder) {
    builderOrderDiscountGroups(builder);
  }
});

export default ordersSlice.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { builderGetIsCloseInventories, builderGetSystemConfig } from './action';
import State, { ModalLoginState } from './types';

const initialState: State = {
  closeStore: undefined,
  config: undefined,
};

const systemSlice = createSlice({
  name: 'system',
  initialState,
  reducers: {
    changeModalLogin($state, active: PayloadAction<ModalLoginState>) {
      $state.modalLogin = active.payload;
    }
  },
  extraReducers(builder) {
    builderGetIsCloseInventories(builder);
    builderGetSystemConfig(builder);
  },
});
export const { changeModalLogin } = systemSlice.actions;
export default systemSlice.reducer;

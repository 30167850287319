import {
  INews, INewsCategories, NewsPayload, IListNews
} from './types';

import axiosInstance from 'services/common/instance';

export const apiGetNews = async (params?: NewsPayload): Promise<APIPaginationResponse<INews[]>> => {
  const res = await axiosInstance.get('/api/v1/news', { params });
  return res.data;
};

export const apiGetNewsBySlug = async (slug: string): Promise<INews> => {
  const res = await axiosInstance.get(`/api/v1/news/slug/${slug}`);
  return res.data.data;
};

export const apiGetNewsCategories = async (): Promise<INewsCategories[]> => {
  const res = await axiosInstance.get('/api/v1/news-categories');
  return res.data.data;
};

export const apiGetNewsCategoriesDetail = async (slug: string): Promise<INewsCategories> => {
  const res = await axiosInstance.get(`/api/v1/news-categories/slug/${slug}`);
  return res.data.data;
};

export const apiGetListNews = async (params?: NewsPayload):
  Promise<IListNews[]> => {
  const res = await axiosInstance.get('/api/v1/news-categories/list-with-news', { params });
  return res.data.data;
};

import React, { useState } from 'react';
import ReactDOM from 'react-dom';

import logo from 'assets/images/Logo.png';
import Button from 'components/atoms/Button';
import Image from 'components/atoms/Image';
import ParagraphMain from 'components/atoms/Typography';

const mainContainer = document.getElementById('root');

const DownloadPopup: React.FC = () => {
  // eslint-disable-next-line no-restricted-globals
  const [open, setOpen] = useState((screen && screen.width < 1200));

  if (!mainContainer || !open) {
    return null;
  }

  return ReactDOM.createPortal(
    <div
      className="o-downloadPopup"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <div className="o-downloadPopup_wrapper">
        <div className="o-downloadPopup_logo">
          <Image src={logo} size="contain" ratio="73x23" alt="Logo" />
        </div>
        <div className="u-mt-28">
          <ParagraphMain.Title modifiers={['600', 'northTexasGreen', 'fontInter', 'center']}>Tải app</ParagraphMain.Title>
        </div>
        <div className="u-mt-12">
          <ParagraphMain.Paragraph modifiers={['500', 'spanishGray', '14x20']}>Tải app ngay để nhận những ưu đãi độc quyền dành cho bạn.</ParagraphMain.Paragraph>
        </div>
        <div className="u-mt-20">
          <Button modifiers={['bg-orangeBronze', 'text-white', 'md']}>TẢI NGAY</Button>
        </div>
        <div className="u-mt-10">
          <Button modifiers={['text-eerieBlack', 'md']} onClick={() => setOpen(false)}>Skip</Button>
        </div>
      </div>
    </div>,
    mainContainer
  );
};

export default DownloadPopup;

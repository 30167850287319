import { yupResolver } from '@hookform/resolvers/yup';
import React, { useCallback, useMemo } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

import Input from 'components/atoms/Input';
import { ToastSingleMode } from 'components/atoms/Toastify';
import Typography from 'components/atoms/Typography';
import Container from 'components/organisms/Container';
import { apiEmailRegister } from 'services/client';

const Register: React.FC = () => {
  const schema = useMemo(() => yup.object({
    email_register: yup.string().required('Vui lòng nhập email!').email('Email không hợp lệ!'),
  }), []);

  const method = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email_register: '',
    }
  });

  const handleSubmit = useCallback(async () => {
    try {
      await apiEmailRegister({ email: method.getValues('email_register') });
      ToastSingleMode({
        type: 'success',
        message: 'Đăng ký nhận tin thành công'
      });
    } catch (error) {
      ToastSingleMode({
        type: 'error',
        message: 'Đăng kí không thành công'
      });
    } finally {
      method.reset();
    }
  }, [method]);

  return (
    <div className="o-footerRegister u-pt-40 u-pb-40">
      <Container>
        <div className="o-footerRegister_wrap d-flex align-items-baseline justify-content-center">
          <Typography.Paragraph
            modifiers={['16x22', '600', 'northTexasGreen']}
            content="Đăng ký nhận bản tin"
          />
          <div className="o-footerRegister_input u-ml-24">
            <FormProvider {...method}>
              <form noValidate>
                <Controller
                  name="email_register"
                  control={method.control}
                  render={({ field, fieldState: { error } }) => (
                    <Input
                      id="footer-input-search"
                      placeholder="Nhập Email của bạn"
                      textSearch="Đăng ký"
                      isSearch
                      error={error?.message}
                      handleClick={() => method.handleSubmit(handleSubmit)()}
                      {...field}
                    />
                  )}
                />
              </form>
            </FormProvider>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Register;

import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  apiGetProductGroupList,
  apiGetProductColorsList,
  apiGetProductTypesList,
  apiGetProductTopSales,
  apiGetProductFavorite,
  apiGetProductViewed,
  apiGetProductPopular,
  apiGetProductBought,
  apiGetProductInterested,
} from 'services/product';
import {
  IProductColor, IProductGroup, IProductTypes
} from 'services/product/types';

export const getProductGroupsAsync = createAsyncThunk<IProductGroup[], void, {}>(
  'product/groups',
  async (_, { rejectWithValue }) => {
    try {
      const res = await apiGetProductGroupList();
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getProductColorsAsync = createAsyncThunk<IProductColor[], void, {}>(
  'product/colors',
  async (_, { rejectWithValue }) => {
    try {
      const res = await apiGetProductColorsList();
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getProductTypesAsync = createAsyncThunk<IProductTypes[], void, {}>(
  'product/types',
  async (_, { rejectWithValue }) => {
    try {
      const res = await apiGetProductTypesList();
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getProductTopSalesAsync = createAsyncThunk<IProductV2[], void, {}>(
  'product/top-sales',
  async (_, { rejectWithValue }) => {
    try {
      const res = await apiGetProductTopSales();
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getProductFavoriteAsync = createAsyncThunk<IProductV2[], void, {}>(
  'product/favorite',
  async (_, { rejectWithValue }) => {
    try {
      const res = await apiGetProductFavorite();
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getProductViewedAsync = createAsyncThunk<IProductV2[], void, {}>(
  'product/viewed',
  async (_, { rejectWithValue }) => {
    try {
      const res = await apiGetProductViewed();
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getProductPopularAsync = createAsyncThunk<IProductV2[], void, {}>(
  'product/popular',
  async (_, { rejectWithValue }) => {
    try {
      const res = await apiGetProductPopular();
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getProductBoughtAsync = createAsyncThunk<IProductV2[], void, {}>(
  'product/bought',
  async (_, { rejectWithValue }) => {
    try {
      const res = await apiGetProductBought();
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getProductInterestedAsync = createAsyncThunk<IProductV2[], void, {}>(
  'product/interested',
  async (_, { rejectWithValue }) => {
    try {
      const res = await apiGetProductInterested();
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

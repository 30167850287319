/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable max-len */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  builderCart, builderListProductByCode
} from './action';
import State from './types';

const initialState: State = {
  acceptCheckout: false,
  estimatedDeliveryDate: new Date(),
};

const cartSlice = createSlice({
  name: 'cartReducer',
  initialState,
  reducers: {
    toggleCheckAction: ($state, action: PayloadAction<number>) => {
      if ($state.cart?.cart_items) {
        const findIndex = $state.cart.cart_items.findIndex((x) => x.id === action.payload);
        if (findIndex !== -1) {
          $state.cart.cart_items[findIndex].checked = !$state.cart.cart_items[findIndex].checked;
        }
      }
    },
    toggleAllAction: ($state, action: PayloadAction<boolean | undefined>) => {
      if ($state.cart?.cart_items) {
        const isAll = action.payload ? false : $state.cart?.cart_items?.filter((x) => x.type === 'product').every((x) => x.checked);
        $state.cart.cart_items = $state.cart.cart_items.map((x) => ({ ...x, checked: !isAll }));
      }
    },
    toggleAcceptCheckoutAction: ($state, { payload }: PayloadAction<boolean>) => {
      $state.acceptCheckout = payload;
    },
    updateCartAction: ($state, action: PayloadAction<ICartsV2>) => {
      const newCart = action.payload;
      newCart.cart_items = newCart.cart_items.map((x) => ({
        ...x,
        checked: x.checked || !!$state.cart?.cart_items.find((m) => x.code === m.code)?.checked,
        info: $state.cart?.cart_items.find((m) => x.code === m.code)?.info
      }));
      $state.cart = newCart;
    },
    cloneCartAction: ($state, action: PayloadAction<ICartsV2>) => {
      const res = action.payload;
      const listPrev = $state.cart?.cart_items || [];
      res.cart_items = res.cart_items.map((x) => {
        let checked = true;
        const find = listPrev.find((m) => m.id === x.id);
        if (find && x.quantity === find.quantity) {
          checked = false;
        }
        return ({
          ...x,
          checked,
        });
      });
      $state.notCheckAll = true;
      $state.cart = res;
    },
    updateEstimatedDeliveryDate: ($state, { payload }: PayloadAction<Date>) => {
      $state.estimatedDeliveryDate = payload;
    },
    clearCardAction: ($state) => {
      if ($state.cart) {
        $state.cart.cart_items = [];
      }
    },
    deleteCartItemAction: ($state, { payload }: PayloadAction<number>) => {
      if ($state.cart?.cart_items) {
        $state.cart.cart_items = $state.cart.cart_items.filter((x) => x.id !== payload);
      }
    },
    notCheckAllAction: ($state, { payload }: PayloadAction<boolean>) => {
      $state.notCheckAll = payload;
    },
  },
  extraReducers(builder) {
    builderListProductByCode(builder);
    builderCart(builder);
  }
});

export const {
  toggleCheckAction,
  toggleAllAction,
  toggleAcceptCheckoutAction,
  updateCartAction,
  updateEstimatedDeliveryDate,
  clearCardAction,
  cloneCartAction,
  deleteCartItemAction,
  notCheckAllAction
} = cartSlice.actions;

export default cartSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';

import { builderGetDeliver } from './action';

interface State {
  deliveryDate?: string;
}

const initialState: State = {};

const deliverSlice = createSlice({
  name: 'deliverReducer',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builderGetDeliver(builder);
  },
});

export default deliverSlice.reducer;

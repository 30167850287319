import React from 'react';

import mapModifiers from 'utils/functions';

export type TextStyle = (GeneralTextStyle)[];

export interface TitleProps extends React.HtmlHTMLAttributes<HTMLHeadingElement> {
  type?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  content?: string;
  modifiers?: TextStyle;
}

const Title: React.FC<TitleProps> = ({
  children,
  type = 'h6',
  content,
  modifiers,
  ...props
}) => {
  const Element = type;
  return (
    content ? (
      <Element
        className={mapModifiers('a-title', modifiers, type)}
        dangerouslySetInnerHTML={{ __html: content }}
        {...props}
      />
    ) : (
      <Element className={mapModifiers('a-title', modifiers, type)} {...props}>
        {children}
      </Element>
    )
  );
};

export default Title;

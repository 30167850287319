import React, { useState } from 'react';

import arrow from 'assets/icons/ic_arrow_to_top.svg';
import useWindowScroll from 'hooks/useWindowScroll';

interface ScrollToTopProps {
}

const ScrollToTop: React.FC<ScrollToTopProps> = () => {
  const [active, setActive] = useState<boolean>(false);
  useWindowScroll(() => {
    if (window.scrollY < 200) {
      setActive(false);
    } else {
      setActive(true);
    }
  });

  return (
    <div
      onClick={() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }}
      className={`o-scrollToTop ${active ? 'active' : ''}`}
    >
      <img src={arrow} alt="arrow" />
    </div>
  );
};

ScrollToTop.defaultProps = {
};

export default ScrollToTop;

import React from 'react';

import PATHS from 'utils/paths';
import './App.scss';

const Home = React.lazy(() => import('./pages/Home'));
const AccountInformation = React.lazy(() => import('./pages/AccountInformation'));
const AllProduct = React.lazy(() => import('./pages/AllProduct'));
const FlowerCare = React.lazy(() => import('./pages/FlowerCare'));
const FlowerCareCategory = React.lazy(() => import('./pages/FlowerCareCategory'));
const FlowerCareDetail = React.lazy(() => import('./pages/FlowerCareDetail'));
const NewLogin = React.lazy(() => import('./pages/NewLogin'));
const NewsDetail = React.lazy(() => import('./pages/NewsDetail'));
const NewsPromotion = React.lazy(() => import('./pages/NewsPromotion'));
const NewsWholesaleCustomer = React.lazy(() => import('./pages/NewsWholesaleCustomer'));
const WholesaleCategory = React.lazy(() => import('./pages/WholesaleCategory'));
const WholesaleDetail = React.lazy(() => import('./pages/WholesaleDetail'));
const OrderManagement = React.lazy(() => import('./pages/OrderManagement'));
const OrderSuccess = React.lazy(() => import('./pages/OrderSuccess'));
const ProductDetail = React.lazy(() => import('./pages/ProductDetail'));
const ProductFavorite = React.lazy(() => import('./pages/ProductFavorite'));
const ProductViews = React.lazy(() => import('./pages/ProductViews'));
const ProductBought = React.lazy(() => import('./pages/ProductBought'));
const NewsPromotionsCategory = React.lazy(() => import('./pages/NewsPromotionsCategory'));
const OrderSchedule = React.lazy(() => import('./pages/OrderSchedule'));
const StackCart = React.lazy(() => import('./pages/StackCart'));
const Confirm = React.lazy(() => import('./pages/Confirm'));
const OrderDetail = React.lazy(() => import('./pages/OrderDetail'));

const routes = [
  {
    path: PATHS.HOME,
    element: Home,
    isPrivate: true,
    pathRedirect: PATHS.NEWS_PROMOTIONS
  },
  {
    path: PATHS.LOGIN,
    element: NewLogin,
    isPrivate: false,
    layout: null
  },
  {
    path: PATHS.ACCOUNT_INFORMATION,
    element: AccountInformation,
    isPrivate: true,
  },
  {
    path: PATHS.PRODUCT_DETAIL,
    element: ProductDetail,
    isPrivate: true,
  },
  {
    path: PATHS.ACCOUNT_ORDER_MANAGEMENT,
    element: OrderManagement,
    isPrivate: true,
  },
  {
    path: PATHS.ORDER_SUCCESS,
    element: OrderSuccess,
    isPrivate: true,
  },
  {
    path: PATHS.ALL_PRODUCT,
    element: AllProduct,
    isPrivate: true,
  },
  {
    path: PATHS.STACK_CART,
    element: StackCart,
    isPrivate: true,
  },
  {
    path: PATHS.PRODUCT_VIEWS,
    element: ProductViews,
    isPrivate: true,
  },
  {
    path: PATHS.PRODUCT_FAVORITE,
    element: ProductFavorite,
    isPrivate: true,
  },
  {
    path: PATHS.PRODUCT_BOUGHT,
    element: ProductBought,
    isPrivate: true,
  },
  {
    path: PATHS.FLOWER_CARE,
    element: FlowerCare,
  },
  {
    path: PATHS.FLOWER_CARE_CATEGORY,
    element: FlowerCareCategory,
  },
  {
    path: PATHS.FLOWER_CARE_DETAIL,
    element: FlowerCareDetail,
  },
  {
    path: PATHS.NEWS_PROMOTIONS,
    element: NewsPromotion,
  },
  {
    path: PATHS.NEW_DETAIL,
    element: NewsDetail,
  },
  {
    path: PATHS.NEWS_WHOLESALE_CUSTOMERS,
    element: NewsWholesaleCustomer,
  },
  {
    path: PATHS.NEWS_PROMOTIONS_CATEGORY,
    element: NewsPromotionsCategory,
  },
  {
    path: PATHS.WHOLESALE_CATEGORY,
    element: WholesaleCategory,
  },
  {
    path: PATHS.WHOLESALE_CUSTOMER_DETAIL,
    element: WholesaleDetail,
  },
  {
    path: PATHS.ORDER_SCHEDULE,
    element: OrderSchedule,
    isPrivate: true,
  },
  {
    path: PATHS.ORDER_DETAIL,
    element: OrderDetail,
    isPrivate: true,
  },
  {
    path: PATHS.CONFIRM,
    element: Confirm,
    isPrivate: true,
  }
];

export default routes;

import React, { useRef } from 'react';
import Modal from 'react-modal';

import Icon, { IconName, IconSize } from 'components/atoms/Icon';
import useClickOutside from 'hooks/useClickOutside';
import mapModifiers from 'utils/functions';

interface Props {
  isOpen?: boolean;
  hasIconClose?: boolean;
  icon?: {
    name: IconName;
    size?: IconSize;
  };
  modifiers?: 'default' | 'promotion' | 'gift' | 'store' | 'calendar' | 'profile'; // add more modifiers
  children?: React.ReactNode;
  handleClose?: () => void;
}

const CustomModal: React.FC<Props> = ({
  isOpen,
  children,
  hasIconClose,
  icon,
  modifiers,
  handleClose,
}) => {
  const refWrapper = useRef<HTMLDivElement>(null);
  const refOpen = useRef<boolean>(false);
  const refModal = useRef<Modal>(null);

  useClickOutside(refWrapper, (e) => {
    if (refOpen.current
      && handleClose
      && (refModal.current as any)?.node?.contains(e.target) // Only close modal focus
    ) {
      handleClose();
      refOpen.current = false;
    }
  });

  return (
    <Modal
      onAfterClose={() => {
        refOpen.current = false;
      }}
      onAfterOpen={() => {
        refOpen.current = true;
      }}
      onRequestClose={handleClose}
      isOpen={!!isOpen}
      closeTimeoutMS={250}
      className={`${mapModifiers('o-modal', modifiers)}`}
      appElement={document.getElementById('root') as HTMLElement}
      ariaHideApp={false}
      portalClassName={mapModifiers('o-modal_portal', isOpen && 'open')}
      bodyOpenClassName="reactmodal-body-open"
      htmlOpenClassName="reactmodal-html-open"
      ref={refModal}
    >
      <div className="o-modal_main">
        <div className="o-modal_wrapper" ref={refWrapper}>
          {hasIconClose && (
            <button type="button" className="o-modal_close" onClick={handleClose}>
              <Icon iconName={icon?.name || 'closeCircle'} size={icon?.size || '20'} />
            </button>
          )}
          <div className="o-modal_body">{children}</div>
        </div>
      </div>
    </Modal>
  );
};

CustomModal.defaultProps = {
  handleClose: undefined,
  modifiers: 'default',
  hasIconClose: true,
};

export default CustomModal;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getProfileAsync, updateProfileAsync } from './thunk';

import { IProfile } from 'services/auth/types';

interface State {
  profile?: IProfile;
}

const initialState: State = {
  profile: undefined,
};

const authSlice = createSlice({
  name: 'authReducer',
  initialState,
  reducers: {
    setProfile: ($state, action: PayloadAction<IProfile>) => {
      $state.profile = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(getProfileAsync.fulfilled, ($state, action) => {
      $state.profile = action.payload;
    });
    builder.addCase(updateProfileAsync.fulfilled, ($state, action) => {
      $state.profile = action.payload;
    });
  },
});

export const { setProfile } = authSlice.actions;

export default authSlice.reducer;

import { IIsCloseInventories } from './type';

import axiosInstance from 'services/common/instance';

const apiGetIsCloseInventories = async (): Promise<IIsCloseInventories> => {
  const res = await axiosInstance.get('/api/v1/customers/inventories/is-close');
  return res.data.data;
};

export default apiGetIsCloseInventories;

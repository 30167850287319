import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import State from './types';

import { ICarts } from 'services/cart/types';

const initialState: State = {
  cart: undefined,
  productBuy: undefined,
  isFuture: undefined
};

const addGiftSlice = createSlice({
  name: 'addGiftReducer',
  initialState,
  reducers: {
    openModalAddGiftAction: (
      $state,
      action: PayloadAction<{ cart: ICarts; productBuy: { code: string }; isFuture?: boolean; }>
    ) => {
      $state.cart = action.payload.cart;
      $state.productBuy = action.payload.productBuy;
      $state.isFuture = action.payload.isFuture;
    },
    closeModalAddGiftAction: ($state) => {
      $state.cart = undefined;
      $state.productBuy = undefined;
      $state.isFuture = undefined;
    },
  },
});

export const {
  openModalAddGiftAction,
  closeModalAddGiftAction,
} = addGiftSlice.actions;

export default addGiftSlice.reducer;

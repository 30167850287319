import React from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import Icon from 'components/atoms/Icon';
import Typography from 'components/atoms/Typography';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { updateGroupAction } from 'store/product';
import PATHS from 'utils/paths';

const CategoryDropdown: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const groups = useAppSelector((state) => state.product.groups);
  const colors = useAppSelector((state) => state.product.colors);

  const onChange = (idx: number) => {
    const newGroup = [...groups].map((x, i) => {
      if (i === idx) {
        return ({
          ...x,
          isChecked: !x.isChecked,
          groups: x.groups.map((m) => ({ ...m, isChecked: !x.isChecked }))
        });
      }
      return x;
    });
    dispatch(updateGroupAction({ groups: newGroup, colors }));
    searchParams.set('page', '1');
    setSearchParams(searchParams);
    if (location.pathname !== PATHS.ALL_PRODUCT) {
      navigate({
        pathname: PATHS.ALL_PRODUCT,
        search: '?page=1'
      });
    }
  };

  return (
    <div className="o-categoryDropdown">
      <div className="o-categoryDropdown_btn">
        <Icon iconName="navigationWhite" size="16" />
        <Typography.Paragraph
          modifiers={['14x20', '500', 'white']}
          content="Danh mục"
        />
      </div>
      <div className="o-categoryDropdown_drop">
        <div className="o-categoryDropdown_list">
          {
            groups?.map((x, idx) => (
              <div onClick={() => onChange(idx)} key={x.id} className={`o-categoryDropdown_item ${x.isChecked ? 'active' : ''}`}>
                <Typography.Paragraph
                  modifiers={['14x20', '500', 'jet']}
                  content={x.name}
                />
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
};

export default CategoryDropdown;

import { IWholesaleCategory, IWholesaleCustomer, IListWholesaleCategory } from './type';

import axiosInstance from 'services/common/instance';
import { NewsPayload } from 'services/news/types';

export const apiGetWholesaleCustomer = async (
    params?: NewsPayload
  ): Promise<APIPaginationResponse<IWholesaleCustomer[]>> => {
    const res = await axiosInstance.get('/api/v1/wholesale-customers', { params });
    return res.data;
};

export const apiGetWholesaleCustomerDetail = async (slug: string): Promise<IWholesaleCustomer> => {
  const res = await axiosInstance.get(`/api/v1/wholesale-customers/${slug}`);
  return res.data.data;
};

export const apiGetWholesaleCategories = async (): Promise<IWholesaleCategory[]> => {
  const res = await axiosInstance.get('/api/v1/wholesale-customer-categories');
  return res.data.data;
};

export const apiGetWholesaleCategoryDetail = async (slug: string): Promise<IWholesaleCategory> => {
  const res = await axiosInstance.get(`/api/v1/wholesale-customer-categories/${slug}`);
  return res.data.data;
};

export const apiGetListWholesaleCustomer = async (
    params?: NewsPayload
  ): Promise<IListWholesaleCategory[]> => {
    const res = await axiosInstance.get('/api/v1/wholesale-customer-categories/list-with-wholesale-customer', { params });
    return res.data.data;
};

import React from 'react';
import {
  Col as ColCustom, ColProps, Row as RowCustom, RowProps,
} from 'react-bootstrap';

import mapModifiers from 'utils/functions';

type Variants =
  | 'fullScreen';

interface ContainerProps {
  modifiers?: (Variants)[];
  children?: React.ReactNode;
}

const Container: React.FC<ContainerProps> = ({
  modifiers, children,
}) => (
  <div className={`container ${mapModifiers('o-container', modifiers)}`}>
    {children}
  </div>
);

Container.defaultProps = {
  modifiers: undefined,
};

export const Row: React.FC<RowProps> = ({ children, className, ...props }) => (
  <RowCustom className={className ? `o-container_row ${className}` : 'o-container_row'} {...props}>{children}</RowCustom>
);

export const Col: React.FC<ColProps> = ({ children, className, ...props }) => (
  <ColCustom className={className ? `o-container_col ${className}` : 'o-container_col'} {...props}>{children}</ColCustom>
);

export default Container;

import React, { useRef } from 'react';

import imgStatusOrder from 'assets/images/image_order_status.png';
import Icon from 'components/atoms/Icon';
import Image from 'components/atoms/Image';
import Link, { LinkProps } from 'components/atoms/Link';
import Typography from 'components/atoms/Typography';
import useClickOutside from 'hooks/useClickOutside';
import useScrollInfinite from 'hooks/useScrollInfinite';
import mapModifiers from 'utils/functions';

interface DropdownUserItem extends LinkProps {
  label?: React.ReactNode;
}

interface DropdownUserProps {
  list: DropdownUserItem[];
}

const User: React.FC<DropdownUserProps> = ({
  list,
}) => (
  <>
    {list.map(({ label, ...navProps }, navIdx) => <Link className="m-dropdown_userItem" key={`${navIdx.toString()}`} {...navProps}>{label}</Link>)}
  </>
);

interface DropdownNotifyItem extends LinkProps {
  title?: string;
  status?: string;
  isSeen?: boolean;
  handleSeen?: () => void;
}

interface DropdownNotifyProps {
  list?: DropdownNotifyItem[];
  onLoadMore?: () => void;
  loading?: boolean;
}

const Notify: React.FC<DropdownNotifyProps> = ({
  list,
  onLoadMore,
  loading
}) => {
  const { setNode } = useScrollInfinite(onLoadMore);
  return (
    <>
      {list?.map(({
        title, status, isSeen, handleSeen, ...navProps
      }, navIdx) => (
        <Link onClick={() => !isSeen && handleSeen && handleSeen()} className={mapModifiers('m-dropdown_notifyItem', isSeen && 'seen')} key={`${navIdx.toString()}`} {...navProps}>
          <div className="m-dropdown_notifyItem_image">
            <Image src={imgStatusOrder} ratio="1x1" />
          </div>
          <div
            ref={(ref) => (navIdx === list.length - 1 ? setNode(ref) : undefined)}
            className="m-dropdown_notifyItem_content"
          >
            <Typography.Paragraph modifiers={['14x20', '600', 'eerieBlack']} content={title} />
            <Typography.Paragraph modifiers={['14x20', '600', 'dimGray']} content={status} />
          </div>
        </Link>
      ))}
      {loading && (
        <div className="u-pt-20 u-pb-20 d-flex justify-content-center">
          <Icon iconName="loadingGreen" size="24" />
        </div>
      )}
      {(!list || list.length <= 0) && !loading
        && (
          <div className="m-dropdown_notifyItem_empty">
            <Typography.Paragraph modifiers={['16x24', '600', 'eerieBlack', 'center']}>Không có thông báo</Typography.Paragraph>
          </div>
        )}
    </>
  );
};

interface DropdownProps {
  children?: React.ReactNode;
  content?: React.ReactNode;
  variant?: 'notify';
  isOpen: boolean;
  handleClose: () => void;
  handleListItem?: () => void;
}

const Wrap: React.FC<DropdownProps> = ({
  children,
  content,
  variant,
  isOpen,
  handleClose,
  handleListItem,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  useClickOutside(ref, handleClose);
  return (
    <div ref={ref} className={mapModifiers('m-dropdown', isOpen && 'open', variant)}>
      <div>
        {children}
      </div>
      <div className="m-dropdown_content">
        <div className="m-dropdown_list">
          <div onClick={handleListItem} className="m-dropdown_listItem">
            {content}
          </div>
        </div>
      </div>
    </div>
  );
};

const Dropdown = {
  Wrap,
  User,
  Notify,
};

export default Dropdown;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FavoriteProductStateTypes } from './types';

const initialState: FavoriteProductStateTypes = {
  listFavoriteStore: []
};

const favoriteProductSlice = createSlice({
  name: 'favoriteProductReducer',
  initialState,
  reducers: {
    updateFavoriteListStoredAction: (
      $state,
      { payload: { id, isFavorite } }:
        PayloadAction<{ id: number, isFavorite: boolean }>
    ) => {
      const existedIdx = $state.listFavoriteStore.findIndex((i) => i.id === id);
      if (existedIdx !== -1) {
        $state.listFavoriteStore[existedIdx].flag = isFavorite;
      } else {
        $state.listFavoriteStore.push({ id, flag: isFavorite });
      }
    },
  },
});

export const {
  updateFavoriteListStoredAction
} = favoriteProductSlice.actions;

export default favoriteProductSlice.reducer;

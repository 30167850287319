import {
  AddGriftParams, CheckGiftDataTypes, CheckGiftReqTypes, CreateOrUpdateCartParams, ICarts
} from './types';

import axiosInstance from 'services/common/instance';

export const apiGetCartsCurrent = async (): Promise<ICarts> => {
  const res = await axiosInstance.get('/api/v1/carts');
  return res.data.data;
};

export const apiCreateOrUpdateCartCurrent = async (
  params: CreateOrUpdateCartParams
): Promise<ICarts> => {
  const res = await axiosInstance.post('/api/v1/cart-items', params);
  return res.data.data;
};

export const apiDeleteItemsCartCurrent = async (id: number): Promise<ICarts> => {
  const res = await axiosInstance.delete(`/api/v1/cart-items/${id}`);
  return res.data.data;
};

export const apiGetCartsFuture = async (): Promise<ICarts> => {
  const res = await axiosInstance.get('/api/v1/future-carts');
  return res.data.data;
};

export const apiCreateOrUpdateCartFuture = async (
  params: CreateOrUpdateCartParams
): Promise<ICarts> => {
  const res = await axiosInstance.post('/api/v1/future-cart-items', params);
  return res.data.data;
};

export const apiDeleteItemsCartFuture = async (id: number): Promise<ICarts> => {
  const res = await axiosInstance.delete(`/api/v1/future-cart-items/${id}`);
  return res.data.data;
};

export const apiAddGift = async (params: AddGriftParams): Promise<ICarts> => {
  const res = await axiosInstance.post('/api/v1/cart-items/add-gift', params);
  return res.data.data;
};

export const apiGetCartsV2 = async (): Promise<ICartsV2> => {
  const res = await axiosInstance.get('/api/v2/carts');
  return res.data.data;
};

export const apiCreateOrUpdateCartV2 = async (
  params: CreateOrUpdateCartParams
): Promise<ICartsV2> => {
  const res = await axiosInstance.post('/api/v2/carts/store', params);
  return res.data.data;
};

export const apiCheckGifts = async (req: CheckGiftReqTypes): Promise<CheckGiftDataTypes[]> => {
  const res = await axiosInstance.post('/api/v2/carts/check-gifts', req);
  return res.data.data;
};

import React, { useCallback, useRef, useState } from 'react';

import Icon from 'components/atoms/Icon';
import Modal from 'components/organisms/Modal';
import Player from 'components/organisms/Player';
import mapModifiers from 'utils/functions';

interface PopupPlayerProps {
  isOpen?: boolean;
  srcBg?: string;
  srcVideo?: string;
  handleClose?: () => void;
}

const PopupPlayer: React.FC<PopupPlayerProps> = ({
  isOpen,
  srcBg,
  srcVideo,
  handleClose
}) => {
  const [isPlay, setIsPlay] = useState(false);
  const ref = useRef<HTMLVideoElement>(null);

  const handlePlay = useCallback(() => {
    setIsPlay(true);
    ref.current?.play();
  }, []);

  return (
    <Modal isOpen={isOpen} handleClose={handleClose}>
      <div className="o-popupPlayer">
        <div className="o-popupPlayer_video">
          <Player
            src={srcVideo}
            controls
            ref={ref}
          />
        </div>
        <div className={mapModifiers('o-popupPlayer_bg', isPlay && 'open')} onClick={handlePlay}>
          <img src={srcBg} alt="video-background" />
          <div className="o-popupPlayer_bg_icon">
            <Icon iconName="playWhite" size="28" />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PopupPlayer;

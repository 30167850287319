import { createAsyncThunk } from '@reduxjs/toolkit';

import { apiDeliveryDate } from 'services/deliver';
import { IDeliveryDate } from 'services/deliver/types';

export const getDeliveryDateAsync = createAsyncThunk<IDeliveryDate, void, {}>('deliver/get', async (_, { rejectWithValue }) => {
  try {
    const res = await apiDeliveryDate();
    return res;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const remove = '';

import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import {
  getProductGroupsAsync,
  getProductColorsAsync,
  getProductTypesAsync,
  getProductTopSalesAsync,
  getProductFavoriteAsync,
  getProductViewedAsync,
  getProductPopularAsync,
  getProductBoughtAsync,
  getProductInterestedAsync,
} from './thunk';
import State from './types';

export const builderProductGroups = (builder: ActionReducerMapBuilder<State>) => {
  builder.addCase(getProductGroupsAsync.pending, ($state) => {
    $state.groupsLoading = true;
  });
  builder.addCase(getProductGroupsAsync.fulfilled, ($state, action) => {
    $state.groupsLoading = false;
    $state.groups = action.payload;
  });
  builder.addCase(getProductGroupsAsync.rejected, ($state) => {
    $state.groupsLoading = false;
    $state.groups = [];
  });
};

export const builderProductColors = (builder: ActionReducerMapBuilder<State>) => {
  builder.addCase(getProductColorsAsync.pending, ($state) => {
    $state.colorsLoading = true;
  });
  builder.addCase(getProductColorsAsync.fulfilled, ($state, action) => {
    $state.colorsLoading = false;
    $state.colors = action.payload;
  });
  builder.addCase(getProductColorsAsync.rejected, ($state) => {
    $state.colorsLoading = false;
    $state.colors = [];
  });
};

export const builderProductTypes = (builder: ActionReducerMapBuilder<State>) => {
  builder.addCase(getProductTypesAsync.pending, ($state) => {
    $state.typesLoading = true;
  });
  builder.addCase(getProductTypesAsync.fulfilled, ($state, action) => {
    $state.typesLoading = false;
    $state.types = action.payload?.map((e) => ({
      id: e.id,
      code: e.code,
      name: e.name,
    }));
  });
  builder.addCase(getProductTypesAsync.rejected, ($state) => {
    $state.typesLoading = false;
    $state.types = [];
  });
};

export const builderProductTopSale = (builder: ActionReducerMapBuilder<State>) => {
  builder.addCase(getProductTopSalesAsync.pending, ($state) => {
    $state.topSalesLoading = true;
  });
  builder.addCase(getProductTopSalesAsync.fulfilled, ($state, action) => {
    $state.topSales = action.payload;
    $state.topSalesLoading = false;
  });
  builder.addCase(getProductTopSalesAsync.rejected, ($state) => {
    $state.topSalesLoading = false;
  });
};

export const builderProductFavorite = (builder: ActionReducerMapBuilder<State>) => {
  builder.addCase(getProductFavoriteAsync.pending, ($state) => {
    $state.favoriteLoading = true;
  });
  builder.addCase(getProductFavoriteAsync.fulfilled, ($state, action) => {
    $state.favorite = action.payload;
    $state.favoriteLoading = false;
  });
  builder.addCase(getProductFavoriteAsync.rejected, ($state) => {
    $state.favoriteLoading = false;
  });
};

export const builderProductViewed = (builder: ActionReducerMapBuilder<State>) => {
  builder.addCase(getProductViewedAsync.pending, ($state) => {
    $state.viewedLoading = true;
  });
  builder.addCase(getProductViewedAsync.fulfilled, ($state, action) => {
    $state.viewed = action.payload;
    $state.viewedLoading = false;
  });
  builder.addCase(getProductViewedAsync.rejected, ($state) => {
    $state.viewedLoading = false;
  });
};

export const builderProductPopular = (builder: ActionReducerMapBuilder<State>) => {
  builder.addCase(getProductPopularAsync.pending, ($state) => {
    $state.popularLoading = true;
  });
  builder.addCase(getProductPopularAsync.fulfilled, ($state, action) => {
    $state.popular = action.payload;
    $state.popularLoading = false;
  });
  builder.addCase(getProductPopularAsync.rejected, ($state) => {
    $state.popularLoading = false;
  });
};

export const builderProductBought = (builder: ActionReducerMapBuilder<State>) => {
  builder.addCase(getProductBoughtAsync.pending, ($state) => {
    $state.boughtLoading = true;
  });
  builder.addCase(getProductBoughtAsync.fulfilled, ($state, action) => {
    $state.bought = action.payload;
    $state.boughtLoading = false;
  });
  builder.addCase(getProductBoughtAsync.rejected, ($state) => {
    $state.boughtLoading = false;
  });
};

export const builderProductInterested = (builder: ActionReducerMapBuilder<State>) => {
  builder.addCase(getProductInterestedAsync.pending, ($state) => {
    $state.interestedLoading = true;
  });
  builder.addCase(getProductInterestedAsync.fulfilled, ($state, action) => {
    $state.interested = action.payload;
    $state.interestedLoading = false;
  });
  builder.addCase(getProductInterestedAsync.rejected, ($state) => {
    $state.interestedLoading = false;
  });
};

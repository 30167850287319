import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { changeModalLogin } from 'store/system';

const useNavigatePrivate = () => {
  const { profile } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const navigatePrivate = (
    href?: string
    ) => {
    if (!profile) {
      dispatch(changeModalLogin({
        isOpenLogin: true,
        redirectTo: href,
      }));
    } else if (href) {
        navigate(href);
      }
  };

  return { navigatePrivate };
};

export default useNavigatePrivate;

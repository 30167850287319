import { createSlice } from '@reduxjs/toolkit';

import { builderNotificationList, builderSeenNotification } from './action';
import State from './types';

const initialState: State = {
  meta: undefined,
  list: [],
  loading: false,
};

const productSlice = createSlice({
  name: 'productReducer',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builderNotificationList(builder);
    builderSeenNotification(builder);
  }
});

export default productSlice.reducer;

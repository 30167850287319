import { createAsyncThunk } from '@reduxjs/toolkit';

import { apiGetProfile, apiUpdateProfile } from 'services/auth';
import { IProfile, ProfilePayload } from 'services/auth/types';

export const getProfileAsync = createAsyncThunk<IProfile, void, {}>('auth/profile', async (_, { rejectWithValue }) => {
  try {
    const res = await apiGetProfile();
    return res;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateProfileAsync = createAsyncThunk<IProfile, ProfilePayload, {}>('auth/profile/update', async (payload, { rejectWithValue }) => {
  try {
    const res = await apiUpdateProfile(payload);
    return res;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const placeholder = null; // remove later;

import React, {
  createContext, useEffect, useMemo, useState,
} from 'react';

import { getCartAsync } from 'store/cart/thunk';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import getOrderDiscountAsync from 'store/orders/thunk';
import { getProductGroupsAsync } from 'store/product/thunk';
import { getIsCloseInventoriesAsync, getSystemConfigAsync } from 'store/system/thunk';

export interface LayoutContextResponse {
  titlePage?: string;
  loading?: boolean;
  setTitlePage?: (title?: string) => void;
  setLoading?: (isLoading: boolean) => void;
  button?: React.ReactNode;
  setButton?: (btn?: React.ReactNode) => void;
}

export const LayoutContext = createContext<LayoutContextResponse>({
  titlePage: undefined,
  loading: undefined,
  setTitlePage: undefined,
  setLoading: undefined,
  button: undefined,
  setButton: undefined
});

interface LayoutProviderProps {
  children?: React.ReactNode
}

const LayoutProvider: React.FC<LayoutProviderProps> = ({ children }) => {
  const profile = useAppSelector((state) => state.auth.profile);
  const cart = useAppSelector((state) => state.cart);
  const groups = useAppSelector((state) => state.product.groups);
  const config = useAppSelector((state) => state.system.config);
  const { listOrderDiscount } = useAppSelector((state) => state.orders);
  const dispatch = useAppDispatch();
  const [titlePage, setTitlePage] = useState<string>();
  const [loading, setLoading] = useState<boolean>();
  const [button, setButton] = useState<React.ReactNode>();

  const isLogin = useMemo(() => !!profile, [profile]);
  const context: LayoutContextResponse = useMemo(() => ({
    titlePage,
    setTitlePage: (title?: string) => setTitlePage(title),
    button,
    setButton: (btn?: React.ReactNode) => setButton(btn),
    loading,
    setLoading: (isLoading?: boolean) => setLoading(isLoading),
  }), [loading, titlePage, button]);

  useEffect(() => {
    if (!cart.cart && isLogin) {
      dispatch(getCartAsync());
    }
    if (!groups?.length && isLogin) {
      dispatch(getProductGroupsAsync());
    }
    if (isLogin) {
      dispatch(getIsCloseInventoriesAsync());
    }
    if (!config && isLogin) {
      dispatch(getSystemConfigAsync());
    }
    if (!listOrderDiscount && isLogin) {
      dispatch(getOrderDiscountAsync());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogin]);
  return (
    <LayoutContext.Provider value={context}>
      {children}
    </LayoutContext.Provider>
  );
};

export default LayoutProvider;

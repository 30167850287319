import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import {
  getDeliveryDateAsync,
} from './thunk';
import State from './types';

export const builderGetDeliver = (builder: ActionReducerMapBuilder<State>) => {
  builder.addCase(getDeliveryDateAsync.fulfilled, ($state, action) => {
    $state.deliveryDate = action.payload.delivery_date;
  });
};

export const remove = '';

/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from '..';

export const apiBlock = createApi({
  reducerPath: 'block-rtk',
  baseQuery: axiosBaseQuery(),
  refetchOnMountOrArgChange: 60 * 10, // 10 phút api sẽ call lại khi mount
  endpoints: (builder) => ({
    getProductTopSales: builder.query<APIPaginationResponse<IProductV2[]>, { page:number, limit:number }>({
      query: (arg) => ({ url: '/api/v2/products/top-sales', method: 'get', params: arg })
    }),
    getProductFavorite: builder.query<APIPaginationResponse<IProductV2[]>, { page:number, limit:number }>({
      query: (arg) => ({ url: '/api/v2/products/favorite', method: 'get', params: arg }),
      keepUnusedDataFor: 0,
    }),
    getProductViewed: builder.query<APIPaginationResponse<IProductV2[]>, { page:number, limit:number }>({
      query: (arg) => ({ url: '/api/v2/products/viewed', method: 'get', params: arg })
    }),
    getProductPopular: builder.query<APIPaginationResponse<IProductV2[]>, { page:number, limit:number }>({
      query: (arg) => ({ url: '/api/v2/products/popular', method: 'get', params: arg })
    }),
    getProductBought: builder.query<APIPaginationResponse<IProductV2[]>, { page:number, limit:number }>({
      query: (arg) => ({ url: '/api/v2/products/bought-products', method: 'get', params: arg })
    }),
    getProductInterested: builder.query<APIPaginationResponse<IProductV2[]>, { page:number, limit:number }>({
      query: (arg) => ({ url: '/api/v2/products/interested', method: 'get', params: arg })
    }),
  }),
});

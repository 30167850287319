import { createAsyncThunk } from '@reduxjs/toolkit';

import { apiGetOrderDiscount } from 'services/orders';
import { OrderDiscountItemTypes } from 'services/orders/types';

const getOrderDiscountAsync = createAsyncThunk<OrderDiscountItemTypes[], void, {}>(
  'orderDiscountReducer/orderDiscount',
  async (_, { rejectWithValue }) => {
    try {
      const res = await apiGetOrderDiscount();
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export default getOrderDiscountAsync;

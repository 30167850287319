/* eslint-disable max-len */
import React from 'react';

import Register from './Register';

import imgLogo from 'assets/images/logo_footer.png';
import Icon from 'components/atoms/Icon';
import Image from 'components/atoms/Image';
import Link, { LinkProps } from 'components/atoms/Link';
import Typography from 'components/atoms/Typography';
import Container, { Col, Row } from 'components/organisms/Container';
import PATHS from 'utils/paths';

const menu = [
  {
    href: PATHS.ALL_PRODUCT,
    label: 'Sản phẩm',
    target: '_self',
  },
  {
    href: '/tin-tuc/hanh-trinh-gan-30-nam-tu-nong-trai-dalat-hasfarm-den-nhung-cua-hang-hoa-tuoi-khap-ba-mien',
    label: 'Giới thiệu',
    target: '_self',
  },
  {
    href: 'https://dalathasfarm.com/trung-tam-phan-phoi',
    label: 'Hệ thống phân phối',
    target: '_blank',
  },
  {
    href: 'https://www.dalathasfarm.com/tai-sao-chon-dalat-hasfarm',
    label: 'Cơ hội nghề nghiệp',
    target: '_blank',
  },
  {
    href: 'https://www.dalathasfarm.com/lien-he',
    label: 'Liên hệ',
    target: '_blank',
  },
];

interface MenuItemProps extends LinkProps {
  label: string;

}

const MenuItem: React.FC<MenuItemProps> = ({ label, ...props }) => (
  <Link {...props} className="d-flex align-items-center u-mt-24 o-footer_menuItem">
    <span className="flex-shrink u-mr-8">
      <Icon iconName="arrowRightGreen" size="16" />
    </span>
    <Typography.Paragraph
      modifiers={['14x20', 'jet', '500']}
      content={label}
    />
  </Link>
);

const Footer: React.FC = () => (
  <footer className="o-footer">
    <Register />
    <Container>
      <div className="u-pt-40 u-pb-40 o-footer_main">
        <Row>
          <Col lg={4}>
            <div className="o-footer_brand">
              <Link href="/">
                <Image
                  size="contain"
                  ratio="335x113"
                  alt="logo"
                  src={imgLogo}
                />
              </Link>
            </div>
            <div className="u-mt-20">
              <Typography.Paragraph
                modifiers={['14x20', 'dimGray', '700']}
              >
                Công ty TNHH Dalat Hasfarm
              </Typography.Paragraph>
              <div className="u-mt-4" />
              <Typography.Paragraph
                modifiers={['14x20', 'dimGray']}
              >
                Dalat Hasfarm - Được biết đến là công ty tiên phong mở đầu cho việc
                trồng hoa chuyên nghiệp tại Việt Nam được thành lập từ năm 1994.
                <br />
                Năm 2013 Dalat Hasfarm được tạp chí Flowers Tech có trụ sở tại Mỹ bình
                chọn là công ty hoa tươi lớn nhất Đông Nam Á.
                <br />
                - Địa chỉ trụ sở: 450 Nguyên Tử Lực, P.8, Đà Lạt, Lâm Đồng, Việt Nam
                <br />
                - Giấy chứng nhận Đăng ký Doanh nghiệp số 5800000167 do Sở Kế hoạch và Đầu tư Tỉnh Lâm Đồng cấp ngày 07/06/1994
              </Typography.Paragraph>
            </div>
          </Col>
          <Col lg={8} className="u-pt-32">
            <Row>
              <Col xs={4} className="u-mt-negative-24">
                {menu.slice(0, 3).map((x, i) => (
                  <MenuItem {...x} key={`item-${i.toString()}`} />
                ))}
              </Col>
              <Col xs={4} className="u-mt-negative-24">
                {menu.slice(3).map((x, i) => (
                  <MenuItem {...x} key={`item-${i.toString()}`} />
                ))}
              </Col>
              <Col xs={4}>
                <Typography.Paragraph
                  modifiers={['14x20', 'jet', '500']}
                  content="Theo dõi chúng tôi"
                />
                <div className="d-flex align-items-center u-mt-6">
                  <Link target="_blank" href="https://www.facebook.com/hoatuoi.dalathasfarm/" className="u-mr-16">
                    <Icon iconName="facebook" size="28" />
                  </Link>
                  <Link target="_blank" href="https://www.youtube.com/channel/UClBMcILPwISsJBztycKKkFQ" className="u-mr-16">
                    <Icon iconName="youtube" size="28" />
                  </Link>
                  <Link target="_blank" href="https://www.instagram.com/dalat.hasfarm/">
                    <Icon iconName="instagram" size="28" />
                  </Link>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div className="u-pt-20 u-pb-20">
        <Typography.Paragraph
          modifiers={['14x20', '500', 'dimGray', 'center']}
          content="Copyright 2022 Dalat Hasfarm"
        />
      </div>
    </Container>
  </footer>
);

export default Footer;

import React from 'react';

import imgLogo from 'assets/images/Logo.png';
import Image from 'components/atoms/Image';
import Link from 'components/atoms/Link';
import Typography from 'components/atoms/Typography';
import Container from 'components/organisms/Container';

interface TitlePageProps {
  title?: string;
  button?: React.ReactNode;
}
const TitlePage: React.FC<TitlePageProps> = ({
  title,
  button
}) => (
  <Container>
    <div className="m-titlePage">
      <div className="m-titlePage_left">
        <Link href="/">
          <Image
            size="contain"
            ratio="176x54"
            alt="logo"
            src={imgLogo}
          />
        </Link>
      </div>
      <div className="m-titlePage_right">
        <Typography.Title type="h6" modifiers={['600', 'northTexasGreen']} content={title} />
        {button && <div>{button}</div>}
      </div>
    </div>
  </Container>
);

export default TitlePage;

import React, { useState } from 'react';

import Icon, { IconName, IconSize } from 'components/atoms/Icon';
import Link, { LinkProps } from 'components/atoms/Link';
import useWindowScroll from 'hooks/useWindowScroll';
import mapModifiers from 'utils/functions';

interface FloatingButtonProps extends LinkProps {
  iconName: IconName,
  iconSize?: IconSize,
  badgeNumber?: number,
  handleCard?: (val: any) => void,
}

const FloatingButton: React.FC<FloatingButtonProps> = ({
  iconName, badgeNumber, iconSize, handleCard, ...props
}) => {
  const [active, setActive] = useState<boolean>(false);

  useWindowScroll(() => {
    if (window.scrollY < 200) {
      setActive(false);
    } else {
      setActive(true);
    }
  });

  return (
    <Link
      className={mapModifiers('m-floatingButton', active && 'active')}
      onClick={(val) => handleCard && handleCard(val)}
      {...props}
    >
      <div className="m-floatingButton_icon">
        <Icon iconName={iconName} size={iconSize} />
      </div>
      {!!(badgeNumber && badgeNumber > 0)
        && (
          <div className="m-floatingButton_badge">
            {badgeNumber > 9 ? '9+' : badgeNumber}
          </div>
        )}
    </Link>
  );
};

FloatingButton.defaultProps = {
  iconSize: '24',
};

export default FloatingButton;

import {
  CheckOrderDiscountReq,
  CreateOrderDraftParams,
  CreateOrdersFutureParams,
  IDetailOrderV2,
  IOrdersAll,
  OrderDiscountItemTypes,
  OrdersAllPayload,
} from './types';

import axiosInstance from 'services/common/instance';

export const apiGetAllOrders = async (params?: OrdersAllPayload):
  Promise<APIPaginationResponse<IOrdersAll[]>> => {
  const res = await axiosInstance.get('/api/v2/orders', { params });
  return res.data;
};

export const apiGetAllFutureOrders = async (params?: OrdersAllPayload):
  Promise<APIPaginationResponse<IOrdersAll[]>> => {
  const res = await axiosInstance.get('/api/v1/customers/future-orders/all', { params });
  return res.data;
};

export const apiCreateOrders = async (params?: { note?: string }):
  Promise<IOrdersAll> => {
  const res = await axiosInstance.post('/api/v1/orders', params);
  return res.data.data;
};

export const apiGetDetailOrders = async (idOrder: number):
  Promise<IOrdersAll> => {
  const res = await axiosInstance.get(`/api/v1/orders/${idOrder}`);
  return res.data.data;
};

export const apiCreateOrdersFuture = async (params: CreateOrdersFutureParams):
  Promise<IOrdersAll> => {
  const res = await axiosInstance.post('/api/v1/customers/future-orders', params);
  return res.data.data;
};

export const apiGetDetailOrdersFuture = async (idOrder: number):
  Promise<IOrdersAll> => {
  const res = await axiosInstance.get(`/api/v1/customers/future-orders/${idOrder}`);
  return res.data.data;
};

export const apiCreateOrderDraft = async (params: CreateOrderDraftParams):
  Promise<{ invoice_id: string }> => {
  const res = await axiosInstance.post('/api/v2/orders', params);
  return res.data.data;
};

export const apiDeleteOrderDraft = async (invoiceId?: string):
  Promise<unknown> => {
  const res = await axiosInstance.delete(`/api/v2/orders/${invoiceId}`);
  return res.data;
};

export const apiGetOrderDetail = async (invoiceId?: string):
  Promise<IDetailOrderV2> => {
  const res = await axiosInstance.get(`/api/v2/orders/${invoiceId}/detail`);
  return res.data.data;
};

export const apiConfirmOrder = async (invoiceId?: string):
  Promise<unknown> => {
  const res = await axiosInstance.post(`/api/v2/orders/${invoiceId}/confirm`);
  return res.data;
};

export const apiCloneOrder = async (invoiceId?: string):
  Promise<ICartsV2> => {
  const res = await axiosInstance.patch(`/api/v2/orders/${invoiceId}/clone`);
  return res.data.data;
};

export const apiCancelOrder = async (invoiceId?: string):
  Promise<unknown> => {
  const res = await axiosInstance.post(`/api/v2/orders/${invoiceId}/cancel`);
  return res.data;
};

export const apiRestoreOrder = async (invoiceId?: string):
  Promise<unknown> => {
  const res = await axiosInstance.post(`/api/v2/orders/${invoiceId}/restore`);
  return res.data;
};

export const apiGetOrderDiscount = async (params?: OrdersAllPayload):
  Promise<OrderDiscountItemTypes[]> => {
  const res = await axiosInstance.get('/api/v2/order-discounts', { params });
  return res.data.data;
};

export const apiCheckOrderDiscount = async (req: CheckOrderDiscountReq):
  Promise<OrderDiscountItemTypes> => {
  const res = await axiosInstance.post('/api/v2/order-discounts/check-discount-codes', req);
  return res.data.data;
};

/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';

import PopupPlayer from 'components/organisms/PopupPlayer';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { closePlayerAction } from 'store/player';

const Player: React.FC = () => {
  const player = useAppSelector((state) => state.player);
  const dispatch = useAppDispatch();
  return (
    <>
      {player.isOpen && (
        <PopupPlayer
          isOpen
          srcVideo={player.srcVideo}
          srcBg={player.srcBg}
          handleClose={() => {
            dispatch(closePlayerAction());
          }}
        />
      )}
    </>
  );
};

export default Player;

import React from 'react';

import FloatingButton from 'components/molecules/FloatingButton';
import FloatingPhone from 'components/organisms/FloatingPhone';
import ScrollToTop from 'components/organisms/ScrollToTop';
import useNavigatePrivate from 'hooks/useNavigatePrivate';
import { useAppSelector } from 'store/hooks';
import PATHS from 'utils/paths';

const ButtonSticky: React.FC = () => {
  const carts = useAppSelector((state) => state.cart);
  const { navigatePrivate } = useNavigatePrivate();
  return (
    <>
      <FloatingButton
        id="badge-cart"
        handleCard={() => navigatePrivate(PATHS.CART)}
        iconName="shoppingCartGreen"
        badgeNumber={carts.cart?.cart_items?.filter((x) => x.type === 'product').length}
        style={{
          bottom: '146px',
          transform: 'unset'
        }}
      />
      <ScrollToTop />
      <FloatingPhone />
    </>
  );
};

export default ButtonSticky;
